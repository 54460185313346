import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Shift }      from './shift';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class ShiftService {
  
  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all shifts
   */
  public getShifts(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Shift[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Shift[]>(environment.api_url + '/shifts', { params: queryParams });
  };
  /**
   * Retrieves shift details by a given id
   *
   * @param idShift
   */
  public getShift(idShift: string): Observable<Shift> { 
    return this.http.get<Shift>(environment.api_url + '/shift/' + idShift);
  };

  /**
   * Retrieves all shifts
   */
  public getOrganizationShifts(idOrganization: number, limit: number, offset: number, sort: string, direction: string, filter: string): Observable<{count: number, rows:Shift[]}> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<{count: number, rows:Shift[]}>(environment.api_url + '/shifts/organization/' + idOrganization, { params: queryParams });
  };
  /**
   * Save shift
   *
   * @param shift
   */
  public saveShift(shift: Shift): Observable<any> { 
    if (shift.idShift) {
      return this.updateShift(shift);
    } else {
      return this.newShift(shift);
    }
    //

  };

  /**
   * Create new shift
   *
   * @param shift
   */
  public newShift(shift: Shift): Observable<any> { 
    return this.http.post(environment.api_url + '/shift', shift);
  };
  
  /**
   * Update shift
   *
   * @param shift
   */
  public updateShift(shift: Shift): Observable<any> { 
    return this.http.put(environment.api_url + '/shift', shift);
  };
  
  /**
   * Delete an shift by a given id
   *
   * @param idShift 
   */
  public removeShift (idShift: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/shift/' + idShift);
  }

  /**
   * get shifts and entity calendar (if there is any)
   * @param idOrganization 
   * @param idEntity 
   * @returns 
   */
  public getEntityShifts(idOrganization: number,idEntity: number): Observable<{count: number, rows:Shift[]}> {
    let queryParams = new HttpParams();
    queryParams = queryParams.set('idEntity', idEntity.toString());  
    return this.http.get<{count: number, rows:Shift[]}>(environment.api_url + '/shifts/organization/' + idOrganization + '/entity/' + idEntity, { params: queryParams });
  };
  
  /**
   * checks if shift is being used
   * @param idShift 
   * @returns 
   */
   public shiftUsedInCalendar(idShift: number): Observable<any> { 
    return this.http.get(environment.api_url + '/calendar/shift/' + idShift);
   };
   
}
