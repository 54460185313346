import { Component } from '@angular/core';
import { Router }    from '@angular/router';

@Component({
  selector: 'fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.css']
})
export class FabComponent {

  icon: string = "add";  
  //idOrganization: string = localStorage.getItem('idOrganization');

  constructor(
    private router: Router
  ) { }

  onTap(event: Event): void {
    //if (event.pointerType == "click" || this.icon == 'edit') {
    if (this.icon == '') {
      this.router.navigate([`/entities/duplicate`]);
      //this.router.navigate([`/entities/duplicate/${this.idOrganization}`]);
    }
  }
}
