import { Injectable } from '@angular/core';

declare var _:any;

@Injectable()
export class MainService {

  constructor() { }

  private state: any;
  private json: any = {};

  setState(state: any): void {
    this.state = state;
  }

  getState(): any {
    return this.state;
  }

  flatten (data: any) {
    var result = {};
    function recurse (cur, prop) {
        if (Object(cur) !== cur) {
            result[prop] = cur;
        } else if (Array.isArray(cur)) {
             for(var i=0, l=cur.length; i<l; i++)
                 recurse(cur[i], prop + "[" + i + "]");
            if (l == 0)
                result[prop] = [];
        } else {
            var isEmpty = true;
            for (var p in cur) {
                isEmpty = false;
                recurse(cur[p], prop ? prop+"."+p : p);
            }
            if (isEmpty && prop)
                result[prop] = {};
        }
    }
    recurse(data, "")
    return result;
  }

  unflatten (data: any) {
    // "use strict";
    if (Object(data) !== data || Array.isArray(data))
        return data;
    var regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
        resultholder = {};
    for (var p in data) {
        var cur = resultholder,
            prop = "",
            m;
        while (m = regex.exec(p)) {
            cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
            prop = m[2] || m[1];
        }
        cur[prop] = data[p];
    }
    return resultholder[""] || resultholder;
  }

  //Transform a JSON object into Array to manage fields
  jsonToArr(obj: any, parent: string): Promise<any> {
    let objF = {};
    objF = this.flatten(obj);
    // let objU = this.unflatten(objF)
    // console.log(objF)
    return new Promise((resolve, reject) => {
      Promise.all(_.map(objF, (value, key) => {
        if(_.isObject(value)) {
          //recursive if has a child
          return this.jsonToArr(value, key);
        } else {
          if(parent) {
            return new Promise((resolve, reject) => { resolve ({ key: `${parent}.${key}`, value: value }) });
          } else {
            return new Promise((resolve, reject) => { resolve ({ key: key, value: value }) });
          }
        }
      }))
      .then((arr) => {
        // resolve (_.flattenDeep(arr));
        resolve (arr);
      });
    });
  }

  arrToJson(arr: any, parent: string): Promise<any>{
    let json = Object.assign({}, {});
    return new Promise((resolve, reject) => {
      if(_.isArray(arr)) {
        //transform to JSON object
        json = _.chain(arr).keyBy("key").mapValues("value").value();
      } else {
        //if not an array is because is recursive and is already transformed to json
        json = arr;
      }
      Promise.all(_.map(json, (obj, key) => {
        //if key has a . mins that has a child
        // let index = key.indexOf(".");
        // if(index > -1) {
        //   let keyName = key.slice(0, index);
        //   let restName = key.slice(index+1, key.length);
        //   let deepObj = {};
        //   deepObj[restName] = obj;
        //   //recursive
        //   return this.arrToJson(deepObj, keyName)
        // } else {
          // if(parent) {
          //   return new Promise((resolve, reject) => {
          //     //add object to parent
          //     let x = {};
          //     x[key] = obj;
          //     //add object to JSON
          //     this.json[parent] = x;
          //     resolve (true);
          //   })
          // } else {
            return new Promise((resolve, reject) => {
              //add object to JSON
              json[key] = obj;
              resolve (true);
            })
          // }
        // }
      })).then((arr) => {
        resolve (this.unflatten(json))
      } )
    });
  }

}
