import * as _ from 'lodash';
import * as moment from 'moment';

export class ShiftsUtils {
    public async checkOverlapShifts(shifts: any) {
        for (let i = 0; i < shifts.length; i++) {
            const shift = shifts[i];
            if (shift.worked) {
                const shiftsClone = _.cloneDeep(shifts);
                const shiftsExcludeCurrentShift = _.remove(shiftsClone, shiftClone => {
                    return shiftClone.idShift != shift.idShift;
                })
                const result = await this.checkTimeOverlapOtherShifts(shift, shiftsExcludeCurrentShift);
                if (result) {
                    return true;
                }
            }
        }
        return false;
    }

    private checkTimeOverlapOtherShifts(shiftSelect, shifts) {
        const momentShiftSelctStart = moment(shiftSelect.start, 'HH:mm:ss');
        const momentShiftSelctEnd = moment(shiftSelect.end, 'HH:mm:ss');
        for (let i = 0; i < shifts.length; i++) {
            const shift = shifts[i];
            if (shift.worked) {
                const momentStart = moment(shift.start, 'HH:mm:ss');
                const momentEnd = moment(shift.end, 'HH:mm:ss');
                if (shift.start === '00:00:00' && shift.end === '00:00:00') {
                    momentEnd.add(1, 'days');
                    momentShiftSelctEnd.add(1, 'days');
                } else if (shift.startDayBefore && momentStart.isSameOrAfter(momentEnd)) {
                    momentStart.subtract(1, 'days');
                    momentShiftSelctStart.subtract(1, 'days');
                } else if (!shift.startDayBefore && momentStart.isSameOrAfter(momentEnd)) {
                    momentEnd.add(1, 'days');
                    momentShiftSelctEnd.add(1, 'days');
                }
                const overlapStart = momentShiftSelctStart.isBetween(momentStart, momentEnd, 'hours', '()');
                const overlapEnd = momentShiftSelctEnd.isBetween(momentStart, momentEnd, 'hours', '()');

                const sameStart = momentShiftSelctStart.isSame(momentStart);
                const samepEnd = momentShiftSelctEnd.isSame(momentEnd);
                
                if (overlapStart || overlapEnd || sameStart || samepEnd) {
                    return true;
                }
            }
        }
        return false;
    }


    public checkShiftCanBeSetStartDayBefore(shift) {
        const start = moment(shift.start, 'HH:mm:ss');
        const end = moment(shift.end, 'HH:mm:ss');
        const isAfter = start.isAfter(end);
        if ((shift.start === shift.end && shift.start !== '00:00:00' && shift.end !== '00:00:00') || isAfter) {
            return true;
        } else {
            return false;
        }
    }

}