import { NgModule } from '@angular/core';
import { ShiftsUtils } from './shifts';
import { ExpressionsUtils } from './expressions';

@NgModule({
  providers: [
    ShiftsUtils,
    ExpressionsUtils
  ]
})
export class UtilsModule { }
