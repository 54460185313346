import { Injectable }       from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    CanActivateChild,
    NavigationExtras,
    CanLoad, Route
}                           from '@angular/router';
import { AuthService }      from './auth.service';
import { AlertService }     from './alert.service';

// import * as Raven from 'raven-js';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

  constructor(
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService
  ) {}

  
  public canActivate(): Observable<boolean> {
    return this.checkLoginOrLogout();
  }

  public canActivateChild(): Observable<boolean> {
    return this.checkLoginOrLogout();
  }

  public canLoad(): Observable<boolean> {
    return this.checkLoginOrLogout();
  }

  private checkLoginOrLogout(): Observable<boolean> {
    return this.authService.isLoggednIn().pipe(tap((res): boolean => {
      if (!res) {
        this.authService.logout();
      }
      return res;
    }));
  }

  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
  //   let url: string = state.url;
  //   return of(this.checkLogin(url));
  //   // return this.checkLoginOrLogout();
  // }

  // canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
  //   return this.canActivate(route, state);
  // }

  // canLoad(route: Route): Observable<boolean> {
  //   let url = `/${route.path}`;

  //   return of(this.checkLogin(url));
  // }

  // // private checkLoginOrLogout(): Observable<boolean> {
  // //   return this.auth.isLoggednIn().pipe(tap((res): boolean => {
  // //     if (!res) {
  // //       this.auth.logout();
  // //     }
  // //     return res;
  // //   }));
  // // }

  // checkLogin(url: string) {
  //   if (this.authService.isLoggedIn) {
  //     return true;
  //   } else if ( localStorage.getItem('token') ) {
  //     this.authService.loadAccount();
  //     return true;
  //   } else{
  //     //Send error message
  //     this.alertService.emitErrorMessage({text: 'You are not logged in', type: 'danger'});
  //     // Navigate to the login page
  //     this.router.navigate(['/login']);
  //     return false;
  //   }
  // }

  // async checkRole(url: string) {
  //   await this.authService.getRole().then((role)=> {
  //     switch(role) {
  //       //admin
  //       case 1: {
  //         return true;
  //       }
  //       //test
  //       //case '2': {
  //         //if(_.includes(url, 'accounts')) {
  //           ////Send error message
  //           //this.alertService.emitErrorMessage({text: 'You do not have access', type: 'danger'});
  //           //return false;
  //         //}
  //         //return true;
  //       //}
  //       default: {
  //         return true;
  //       }
  //     }
  //   })

  // }

}
