import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { DataloggerSoftware } from './dataloggerSoftware';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class DataloggerSoftwareService {
  
  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all dataloggerSoftwares
   */
  public getDataloggerSoftwares(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<DataloggerSoftware[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<DataloggerSoftware[]>(environment.api_url + '/dataloggerSoftwares', { params: queryParams });
  };
  /**
   * Retrieves all dataloggerSoftwares by a id organization
   * @param idOrganization
   */
  public getOrganizationDataloggerSoftwares(idOrganization: string, limit: number, offset: number, sort: string, direction: string, filter: string): Observable<DataloggerSoftware[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<DataloggerSoftware[]>(environment.api_url + '/dataloggerSoftwares/organization/' + idOrganization, { params: queryParams });
  };
  /**
   * Retrieves dataloggerSoftware details by a given id
   *
   * @param idDataloggerSoftware
   */
  public getDataloggerSoftware(idDataloggerSoftware: string): Observable<DataloggerSoftware> { 
    return this.http.get<DataloggerSoftware>(environment.api_url + '/dataloggerSoftware/' + idDataloggerSoftware);
  };

  /**
   * Save dataloggerSoftware
   *
   * @param dataloggerSoftware
   */
  public saveDataloggerSoftware(dataloggerSoftware: DataloggerSoftware): Observable<DataloggerSoftware> {
    if (dataloggerSoftware.idDataloggerSoftware) {
      return this.updateDataloggerSoftware(dataloggerSoftware);
    } else {
      return this.newDataloggerSoftware(dataloggerSoftware);
    }
    //return null;
  };

  /**
   * Create new dataloggerSoftware
   *
   * @param dataloggerSoftware
   */
  public newDataloggerSoftware(dataloggerSoftware: DataloggerSoftware): Observable<DataloggerSoftware> { 
    return this.http.post<DataloggerSoftware>(environment.api_url + '/dataloggerSoftware', dataloggerSoftware);
  };

  /**
   * Update dataloggerSoftware
   *
   * @param dataloggerSoftware
   */
  public updateDataloggerSoftware(dataloggerSoftware: DataloggerSoftware): Observable<DataloggerSoftware> { 
    return this.http.put<DataloggerSoftware>(environment.api_url + '/dataloggerSoftware', dataloggerSoftware);
  };

  /**
   * Delete an dataloggerSoftware by a given id
   *
   * @param idDataloggerSoftware
   */
  public removeDataloggerSoftware (idDataloggerSoftware: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/dataloggerSoftware/' + idDataloggerSoftware);
  }

  /**
   * Retrieves dataloggerSoftware details by a given id
   *
   */
  public getFreeDataloggerSoftwares(): Observable<DataloggerSoftware[]> { 
    return this.http.get<DataloggerSoftware[]>(environment.api_url + '/freeDataloggerSoftwares');
  };

  /**
   * Retrieves dataloggerSoftware details by a given id
   *
   * @param id
   */
  public getEntityDataloggerSoftwares(id: string): Observable<DataloggerSoftware[]> { 
    return this.http.get<DataloggerSoftware[]>(environment.api_url + '/dataloggerSoftwares/entity/' + id);
  };

}
