import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule,
         MatInputModule,
         MatCardModule,
         MatSelectModule,
         MatRadioModule,
         MatDialogModule,
         MatButtonModule
        }                     from '@angular/material';

import { LanguagesRoutingModule }  from './languages-routing.module';
import { LanguageListComponent }   from './language-list/language-list.component';
import { LanguageEditComponent }   from './language-edit/language-edit.component';
// import { RoleService }         from './role.service';
import { LanguageService }         from './language.service';
// import { DialogComponent }     from './role-edit/role-edit.component';

import { SharedModule }        from './../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    MatButtonModule,
    LanguagesRoutingModule,
    SharedModule
  ],
  entryComponents: [
    // DialogComponent
  ],
  declarations: [
    LanguageListComponent,
    LanguageEditComponent,
    // DialogComponent,
  ],
  providers: [ LanguageService ]
})
export class LanguagesModule { }
