import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }  from '@angular/forms';

import { MainRoutingModule }              from './main-routing.module';
import { MainComponent }                  from './main.component';
import { MainService }                    from './main.service';
import { HeaderComponent }                from './header/header.component';
import { SidebarComponent }               from './sidebar/sidebar.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { FabComponent }                   from './fab/fab.component';
import { AppsModule }                     from './apps/apps.module';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2Piwik } from 'angulartics2/piwik';
// import { Angular2PiwikModule } from 'angulartics2/piwik';
import { AppService } from './apps/app.service';
import { MatToolbarModule,
  MatSidenavModule,
  MatMenuModule,
  MatGridListModule,
  MatExpansionModule,
  MatListModule,
  MatButtonModule,
  MatIconModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MainRoutingModule,
    AppsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatMenuModule,
    MatGridListModule,
    MatListModule,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    Angulartics2Module.forRoot(),
  ],
  declarations: [
    MainComponent,
    HeaderComponent,
    ImpersonateComponent,
    SidebarComponent,
    FabComponent
  ],
  providers: [
    MainService,
    AppService,
  ]
})
export class MainModule {}
