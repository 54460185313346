import { Injectable }             from '@angular/core';
import { Router, Resolve, 
         RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';
import { Observable }             from 'rxjs/Observable';

import { Role }          from './../role';
import { RoleService }   from './../role.service';


@Injectable()
export class RoleListResolver implements Resolve<Role[]> {
  constructor(private roleService: RoleService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Role[]> {
    //return this.roleService.getRoles()
    return this.roleService.getRoles(10, 0, 'weight', 'asc', '')
  }
}
