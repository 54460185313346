import { Injectable } from '@angular/core';

import { Store } from "./store";
import { QueryState } from "./query-state";

@Injectable({
  providedIn: 'root'
})
export class QueryStore extends Store<QueryState> {
  constructor() {
    super(new QueryState());
  }

  /**
   * Create a new query (doesn't check for id duplicates)
   * @param id url and component
   * @param pageSize page size
   * @param pageIndex page index
   * @param sort
   * @param direction asc or desc
   * @param filter
   */
  addQuery(id: { url: string, component: string }, pageSize: number, pageIndex: number, sort: string, direction: string, filter: {}) {
    this.setState({
      ...this.state,
      queries: [
        ...this.state.queries,
        {
          id: id,
          pageSize: pageSize,
          pageIndex: pageIndex,
          sort: sort,
          direction: direction,
          filter: filter
        }
      ]
    });
  }

  /**
   * Updates an existing query
   * @param id url and component
   * @param pageSize page size
   * @param pageIndex page index
   * @param sort
   * @param direction asc or desc
   * @param filter
   */
  updateQuery(id: { url: string, component: string }, pageSize: number, pageIndex: number, sort: string, direction: string, filter: {}){
    this.setState({
      ...this.state,
      queries: this.state.queries.map(q => {
        if (q.id.url == id.url && q.id.component == id.component) {
          return {
            ...q, 
            pageSize: pageSize,
            pageIndex: pageIndex,
            sort: sort,
            direction: direction,
            filter: filter
          }
        }
        return q;
      })
    })
  };
  
  /**
   * Get (first) query ocurrence by id
   * @param id url and component
   */
  getQuery(id: { url: string, component: string }){
    let query = null;
    this.state.queries.some((q) => {
        return (q.id.url == id.url && q.id.component == id.component) ? ((query = q), true) : false;
    });
    return query;
  }
  
  /**
   * Delete (first) query ocurrence by id
   * @param id url and component
   */
  deleteQuery(id: { url: string, component: string }){
    this.state.queries.some((q, i) => {
        return (q.id.url == id.url && q.id.component == id.component) ? ((this.state.queries.splice(i, 1)), true) : false;
    });
  }

  /**
   * Delete all queries
   */
  deleteAllQueries(){
    this.setState(new QueryState);
  }

}