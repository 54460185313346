import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Setting } from './setting';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class SettingService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all settings
   */
  public getSettings(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Setting[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Setting[]>(environment.api_url + '/settings', { params: queryParams });
  };
  /**
   * Retrieves setting details by a given id
   *
   * @param idSetting
   */
  public getSetting(idSetting: string): Observable<Setting> { 
    return this.http.get<Setting>(environment.api_url + '/setting/' + idSetting);
  };

  /**
   * Save setting
   *
   * @param setting
   */
  public saveSetting(setting: Setting): Observable<any> {
    if (setting.idSetting) {
      return this.updateSetting(setting);
    } else {
      return this.newSetting(setting);
    }
    //return null;
  };

  /**
   * Create new setting
   *
   * @param setting
   */
  public newSetting(setting: Setting): Observable<any> { 
    return this.http.post(environment.api_url + '/setting', setting);
  };

  /**
   * Update setting
   *
   * @param setting
   */
  public updateSetting(setting: Setting): Observable<any> { 
    return this.http.put(environment.api_url + '/setting', setting);
  };

  /**
   * Delete an setting by a given id
   *
   * @param idSetting
   */
  public removeSetting (idSetting: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/setting/' + idSetting);
  }

  /**
   * Retrieves settings by a given an entity id
   *
   * @param idEntity
   */
  public getEntitySettings(idEntity: string): Observable<Setting[]> { 
    return this.http.get<Setting[]>(environment.api_url + '/settings/entity/' + idEntity);
  };

  /**
   * Create new settings for entity, based on mastersettings
   *
   * @param setting
   */
  public newEntitySettings(settings: any[]): Observable<any> { 
    return this.http.post(environment.api_url + '/entitySettings', settings);
  };

  public generateMapSettings(mapSettingObject: any): Observable<any> { 
    return this.http.post(environment.api_url + '/mapSettings', mapSettingObject);
  };

}
