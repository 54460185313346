import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTableModule,
         MatSortModule,
         MatPaginatorModule,
         MatFormFieldModule,
         MatDialogModule,
         MatIconModule,
         MatButtonModule,
         MatInputModule,
         MatCheckboxModule,
         MatProgressSpinnerModule,
         MatSlideToggleModule,
         MatCardModule,
         MatTooltipModule,
         MatChipsModule,
       MatExpansionModule, 
       MatSelectModule} from '@angular/material';

import { MatGridListModule } from '@angular/material/grid-list';

import { TableListComponent } from './tableList/tableList.component';

import { TableListHttpComponent, DialogComponent2 } from './tableListHttp/tableListHttp.component';
import { ExpandableTableListHttpComponent } from './expandableTableListHttp/expandableTableListHttp.component';

// import { DialogComponent }    from './dialog/dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardHeaderEditComponent,
         DialogComponent }    from './cardHeader-edit/cardHeader-edit.component';
import { MapDialogComponent } from '../entities/entity-edit/entity-edit.component';
import { MapDialogComponent2 } from '../entities/entity-new/entity-new.component';

@NgModule({
  imports: [
    CommonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatGridListModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatCardModule,
    MatTooltipModule,
    MatChipsModule,
    MatExpansionModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    TableListComponent,
    TableListHttpComponent,
    CardHeaderEditComponent,
    ExpandableTableListHttpComponent,
    DialogComponent,
    DialogComponent2,
    MapDialogComponent,
    MapDialogComponent2
  ],
  entryComponents: [ DialogComponent, DialogComponent2 , MapDialogComponent, MapDialogComponent2],
  providers: [],
  exports: [
    TableListComponent,
    TableListHttpComponent,
    ExpandableTableListHttpComponent,
    // DialogComponent,
    CardHeaderEditComponent
  ]
})

export class SharedModule {}
