import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CalendarService } from '../calendar.service';
import { AlertService } from '../../../alert.service';
import { Shift } from '../../shifts/shift';
import * as _ from 'lodash';
import { forkJoin } from 'rxjs';
import { ShiftsUtils } from '../../../utils/shifts';

export interface DialogData {
    date: Date;
    organization: String,
    workShifts: any[],
    shifts: Shift[]
}

@Component({
    selector: 'calendar-day-edit',
    templateUrl: 'calendar-day-edit.component.html',
    styleUrls: ['calendar-day-edit.component.css']
})
export class CalendarDayEditComponent {

    public workShifts: any[];
    public shifts: Shift[] = [];
    public organizationName: String;
    public selectedDate: Date;
    public pastDate = true;

    constructor(
        public dialogRef: MatDialogRef<CalendarDayEditComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private calendarService: CalendarService,
        private alertService: AlertService,
        private shiftsUtils: ShiftsUtils
    ) {
        this.selectedDate = data.date;
        this.workShifts = JSON.parse(JSON.stringify(data.workShifts));
        this.organizationName = data.organization;
        this.removeDuplicateShifts(data.shifts);
    }

    private checkForPastDates() {
        const today = new Date();
        return this.selectedDate < today;
    }

    private async removeDuplicateShifts(shifts: Shift[]) {
        this.pastDate = await this.checkForPastDates()
        for (let i = 0; i < shifts.length; i++) {
            const shift = shifts[i];
            const index = _.findIndex(this.workShifts, ['idShift', shift.idShift]);
            if (index === -1) {
                shift['isShiftNotPreviouslyActivated'] = true;
                this.shifts.push(shift);
            }
        }
        this.workShifts = this.workShifts.concat(this.shifts);
    }

    public async selectWorkShift(event, index) {
        if (event.checked) {
            const resultOverlap = await this.shiftsUtils.checkOverlapShifts(this.workShifts);
            if (resultOverlap) {
                this.workShifts[index].worked = !event.checked;
                event.source.checked = !event.checked;
                this.alertService.emitErrorMessage({ text: 'One or more of the shifts are overlapping and will not be activated.', type: 'danger' });
            }
        }
    }

    onSave(): void {
        this.saveEntityCalendar();
    }

    async saveEntityCalendar() {
        const entityWorkedShifts = [];
        const especialDayWorkedShifts = [];
        for (let i = 0; i < this.workShifts.length; i++) {
            const work = this.workShifts[i];
            if (work.idEntityWorkedShift) {
                const data = {
                    idEntityWorkedShift: work.idEntityWorkedShift, 
                    date: work.date,
                    worked: work.worked
                }
                entityWorkedShifts.push(data) 
            } else {
                const data = {
                    idShift: work.idShift, 
                    idEntity: work.idEntity, 
                    date: work.date,
                    worked: work.worked
                }
                especialDayWorkedShifts.push(data);
            }
        }
        const observableCall = [];
        if (entityWorkedShifts.length > 0) observableCall.push(this.calendarService.updateEntityWorkedCalendar({entityWorkedShifts: entityWorkedShifts}));
        if (especialDayWorkedShifts.length > 0) observableCall.push(this.calendarService.updateSpecialDayShiftWorked(especialDayWorkedShifts));
        forkJoin(observableCall).subscribe((result) => {
            this.dialogRef.close(true);
        }, (err)=>{
            this.alertService.emitErrorMessage({ text: (err && err.error) ? err.error: 'Error updating calendar', type: 'danger' });
            }
        );
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

}