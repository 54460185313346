import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { MapVariable }      from './mapVariable';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class MapVariableService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all mapVariables
   */
  public getMapVariables(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<MapVariable[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<MapVariable[]>(environment.api_url + '/mapVariables', { params: queryParams });
  };
  public getMapVariablesBymodel(idDatasourceModel: string): Observable<MapVariable[]> {  
    return this.http.get<MapVariable[]>(environment.api_url + '/mapVariablesByModel/' + idDatasourceModel);

  };

  /**
   * Retrieves mapVariable details by a given id
   *
   * @param idMapVariable
   */
  public getMapVariable(idMapVariable: string): Observable<MapVariable> { 
    return this.http.get<MapVariable>(environment.api_url + '/mapVariable/' + idMapVariable);
  };

  /**
   * Save mapVariable
   *
   * @param mapVariable
   */
  public saveMapVariable(mapVariable: MapVariable): Observable<any> {
    if (mapVariable.idMapVariable) {
      return this.updateMapVariable(mapVariable);
    } else {
      return this.newMapVariable(mapVariable);
    }
    //return null;
  };

  /**
   * Create new mapVariable
   *
   * @param mapVariable
   */
  public newMapVariable(mapVariable: MapVariable): Observable<any> { 
    return this.http.post(environment.api_url + '/mapVariable', mapVariable);
  };

  /**
   * Update mapVariable
   *
   * @param mapVariable
   */
  public updateMapVariable(mapVariable: MapVariable): Observable<any> { 
    return this.http.put(environment.api_url + '/mapVariable', mapVariable);
  };

  /**
   * Delete an mapVariable by a given id
   *
   * @param idMapVariable
   */
  public removeMapVariable (idMapVariable: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/mapVariable/' + idMapVariable);
  }

  /**
   * Retrieves mapVariable details by a given id
   *
   * @param idOrganization
   */
  public getOrganizationMapVariables(idOrganization: string): Observable<MapVariable[]> { 
    return this.http.get<MapVariable[]>(environment.api_url + '/mapVariable/organization/' + idOrganization);
  };

}
