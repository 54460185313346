import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { App }      from './app';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";

@Injectable()
export class AppService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all apps
   */
  
  public getApps(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<App[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<App[]>(environment.api_url + '/apps', { params: queryParams });
  };

  /**
   * Retrieves app details by a given id
   *
   * @param id
   */
  public getApp(idApp: string): Observable<App> { 
    return this.http.get<App>(environment.api_url + '/app/' + idApp);
  };
  /**
   * Save app
   *
   * @param app
   */
  public saveApp(app: App): Observable<any> {
    if (app.idApp) {
      return this.updateApp(app);
    } else {
      return this.newApp(app);
    }
    //return null;
  };

  /**
   * Create new app
   *
   * @param app
   */

  public newApp(app: App): Observable<any> { 
    return this.http.post(environment.api_url + "/app", app)
  };
  /**
   * Update app
   *
   * @param app
   */

  public updateApp(app: App): Observable<any> { 
    return this.http.put(environment.api_url + "/app", app)  
  };
  /**
   * Delete an app by a given id
   *
   * @param id
   */
  public removeApp(idApp: number): Observable<any> { 
    return this.http.delete(environment.api_url + "/app/" + idApp);
  }
  /**
   * Retrieves app details by a given app id
   *
   * @param id
   */
  public getAppApps(idApp: string): Observable<App[]> { 
    return this.http.get<App[]>(environment.api_url + '/apps/app/' + idApp);
  };
  /**
   * Retrieves entity details by a given a master variable
   *
   * @param id
   */
  public getMasterVariableApps(id: string): Observable<App[]> { 
    return this.http.get<App[]>(environment.api_url + '/apps/masterVariable/' + id);
  };
  /**
   * Retrieves entity details by a given an organization
   *
   * @param id
   */
  public getOrganizationApps(id: string): Observable<App[]> { 
    return this.http.get<App[]>(environment.api_url + '/apps/organization/' + id);
  };
  /**
   * Retrieves entity details by a given an organization
   *
   * @param id
   */
  public getAccountApps(id: string): Observable<App[]> { 
    return this.http.get<App[]>(environment.api_url + '/apps/account/' + id);
  };
  /**
   * Retrieves entity details by a given an organization
   *
   * @param id
   */
  public getLicenseApps(id: string): Observable<App[]> { 
    return this.http.get<App[]>(environment.api_url + '/apps/license/' + id);
  };
  /**
   * Check if the organization of your account has access to a machine licensed to a given App 
   *
   * @param id
   */
  public checkLicensedApp(id: string): Observable<any> { 
    return this.http.get(environment.api_url + '/app/licensed/' + id);
  };
  
  
  public getAppsExtended(): Observable<App[]> { 
    return this.http.get<App[]>(environment.api_url + '/apps/extended');
  };

  public getLicensedApps(idEntity: string): Observable<App[]> { 
    return this.http.get<App[]>(environment.api_url + '/apps/licensed/entity/' + idEntity);
  };
 
  public getEntityApps(idEntity: string): Observable<App[]> { 
    return this.http.get<App[]>(environment.api_url + '/apps/entity/' + idEntity);
  };
}
