import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';

import { Datasource }   from './datasource';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class DatasourceService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all datasources
   */
  public getDatasources(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Datasource[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Datasource[]>(environment.api_url + '/datasources', { params: queryParams });
  };
  /**
   * Retrieves datasource details by a given id
   *
   * @param idDatasource
   */
  public getDatasource(idDatasource: string): Observable<Datasource> { 
    return this.http.get<Datasource>(environment.api_url + '/datasource/' + idDatasource);
  };

  /**
   * Save datasource
   *
   * @param datasource
   */
  public saveDatasource(datasource: Datasource): Observable<Datasource> {
    if (datasource.idDatasource) {
      return this.updateDatasource(datasource);
    } else {
      return this.newDatasource(datasource);
    }
    //return null;
  };

  /**
   * Create new datasource
   *
   * @param datasource
   */
  public newDatasource(datasource: Datasource): Observable<Datasource> { 
    return this.http.post<Datasource>(environment.api_url + '/datasource', datasource);
  };

  /**
   * Update datasource
   *
   * @param datasource
   */
  public updateDatasource(datasource: Datasource): Observable<Datasource> { 
    return this.http.put<Datasource>(environment.api_url + '/datasource', datasource);
  };

  /**
   * Delete an datasource by a given id
   *
   * @param idDatasource
   */
  public removeDatasource (idDatasource: number): Observable<any> { 
    return this.http.delete<Datasource>(environment.api_url + '/datasource/' + idDatasource);
  }

  /**
   * Retrieves datasources by a given an organization id
   *
   * @param idOrganization
   */
  public getOrganizationDatasources(idOrganization: string): Observable<Datasource[]> { 
    return this.http.get<Datasource[]>(environment.api_url + '/datasources/organization/' + idOrganization);
  };


  /**
   * Retrieves datasources by a given a datalogger id
   *
   * @param idDatalogger
   */
  public getDataloggerDatasources(idDatalogger: string): Observable<Datasource[]> { 
    return this.http.get<Datasource[]>(environment.api_url + '/datasources/datalogger/' + idDatalogger);
  };

  /**
   * Retrieves datasources by a given an entity id
   *
   * @param idEntity
   */
  public getEntityDatasources(idEntity: string): Observable<Datasource[]> { 
    return this.http.get<Datasource[]>(environment.api_url + '/datasources/entity/' + idEntity);
  };


   /**
   * Delete specified files on array param from machine folder
   *
   * @param result
   */
  public deleteAlarmTextFile(result: any): Observable<any> { 
    return this.http.post(environment.api_url + '/datasource/alarmTextFiles', result);
  };

     /**
   * Delete specified files on array param from machine folder
   *
   * @param id
   */

  public uploadAlarmTextFile(result: any): Observable<any> { 
    return this.http.post(environment.api_url + '/datasource/uploadAlarmTextFiles/', result);
  };

  /**
   * Get datasource status from given datasource id
   * @param idDatasource
   */
  public getDatasourceStatus(idDatasource: number): Observable<any> { 
    return this.http.get(environment.api_url + '/datasource/status/' + idDatasource);
  };

  /**
   * Get available MTConnect device names
   * @param idDataloggerHardware
   * @param address
z   */
  public getMTConnectDevices(idDataloggerHardware: number, address: string): Observable<any> { 
    return this.http.get(environment.api_url + '/mtconnect/deviceNames/dataloggerHardware/' + idDataloggerHardware + '/address/' + address);
  };


}
