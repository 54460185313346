import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Role }        from './../role';
import { RoleService } from './../role.service';
import { AuthService } from '../../../auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css'],
  providers: [ RoleService ]
})

export class RoleListComponent implements OnInit {

  roles: Role[];
  module: string = 'Role';
  fields: Array<any>= [
    { id: 'name', display: 'name', header: 'Name', type: 'chip2', sortable: true, filterable: true },
    { id: 'description', display: 'description', header: 'Description', sortable: true, filterable: true },
    { id: 'weight', display: 'weight', header: 'Weight', sortable: true, filterable: true }
  ];
  menuTitle: string = 'Roles';
  loading: boolean = true;

  query: any = {pageSize: 10, pageIndex: 0, sort: null, direction: null, filter: null};
  private subscription: Subscription = new Subscription();

  constructor(
    private roleService: RoleService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.getRole().then((role) => {
      if ( role != 1) {
        this.router.navigate(['/']);
      }
    });
  }

  onTableListEmitter(message:any):void {
    switch (message.text) {
      case 'new':
        this.onNew();
        break;
      case 'select':
        this.onSelect(message.data);
        break;
      case 'edit':
        this.onEdit(message.data);
        break;
      case 'remove':
        this.onRemove(message.data);
        break;
      case 'getData':
        this.query = message.data;
        this.onGetData();
        break;
    }
  }

  onNew(){
    this.router.navigate(['/roles/new']);
  }

  onSelect(role: Role){
    this.router.navigate(['/roles/edit',role.idRole ]);
  }

  onEdit(role: Role){
    this.router.navigate(['/roles/edit',role.idRole ]);
  }

  onRemove(role: Role){
    this.roleService.removeRole(role.idRole);
  }

  onGetData(){
    this.loading = true;
    this.unsubscribeData();
    this.subscription = this.roleService.getRoles(this.query.pageSize, this.query.pageIndex, this.query.sort, this.query.direction, this.query.filter)
      .subscribe( data => {
        this.loading = false;
        this.roles = data;
      })
  }

  ngOnDestroy() {
    this.unsubscribeData();
  }

  private unsubscribeData() {
    if (this.subscription != null) {
        this.subscription.unsubscribe();
    }
  }

}
