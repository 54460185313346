import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccountListComponent }   from './account-list/account-list.component';
// import { AccountListResolver }    from './account-list/account-list-resolver.service';
// import { AccountListAppEditResolver }    from './account-list/account-list-app-edit-resolver.service';
import { AccountEditComponent }   from './account-edit/account-edit.component';
import { AccountEditResolver }    from './account-edit/account-edit-resolver.service';
//import { AccountAppEditComponent } from './account-app-edit/account-app-edit.component';
//import { AccountAppNewResolver }  from './account-app-edit/account-app-new-resolver.service';

import { RoleListResolver }         from './../roles/role-list/role-list-resolver.service';
import { OrganizationListResolver } from './../organizations/organization-list/organization-list-resolver.service';
import { LanguageListResolver } from './../languages/language-list/language-list-resolver.service';
import { AccountAppEditResolver } from './account-app-edit/account-app-edit-resolver.service';
import { AppListResolver } from '../apps/app-list/app-list-resolver.service';
// import { AccountAppListResolver } from '../apps/app-list/account-app-list-resolver.service';
import { OrganizationAppListResolver } from '../apps/app-list/organization-app-list-resolver.service';

const accountsRoutes: Routes = [
  //{ path: '', component: AccountListComponent },
  { path: '',
    children: [
      //{path: 'accounts/:idOrganization', component: AccountListComponent, resolve: {accounts: AccountListResolver} },
      //{path: 'register', component: AccountEditComponent },
      {path: 'new', component: AccountEditComponent,
        resolve: {
          roles: RoleListResolver,
          organizations: OrganizationListResolver,
          languages: LanguageListResolver,
          // apps: AppListResolver
        }
      },
      {path: 'edit/:id', component: AccountEditComponent,
        resolve: {
          account: AccountEditResolver,
          roles: RoleListResolver,
          organizations: OrganizationListResolver,
          languages: LanguageListResolver,
          // apps: AppListResolver,
          // accountApps: AccountAppListResolver,
          organizationApps: OrganizationAppListResolver
        }
      },
      {path: 'profile/:id', component: AccountEditComponent,
        resolve: {
          account: AccountEditResolver,
          roles: RoleListResolver,
          organizations: OrganizationListResolver,
          languages: LanguageListResolver,
          // apps: AppListResolver,
          // accountApps: AccountAppListResolver,
          organizationApps: OrganizationAppListResolver
        }
      },
      //{path: '', component: AccountListComponent, resolve: {accounts: AccountListResolver} }
      {path: '', component: AccountListComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(accountsRoutes)],
  exports: [RouterModule],
  providers: [
    // AccountListResolver,
    // AccountListAppEditResolver,
    AccountEditResolver,
    //AccountAppNewResolver,
    AccountAppEditResolver
  ]
})
export class AccountsRoutingModule { }
