import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
  Output
} from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationStart } from '@angular/router';
// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Location } from '@angular/common';
import { FormControl, ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Entity } from './../entity';
import { EntityService } from './../entity.service';

import { EntityModel } from './../../entityModels/entityModel';
import { DataloggerHardware } from './../../dataloggerHardwares/dataloggerHardware';
import { DataloggerSoftware } from './../../dataloggerSoftwares/dataloggerSoftware';
import { Datasource } from './../../datasources/datasource';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as L from 'leaflet';
import 'leaflet-control-geocoder';
//import { BBJ, Source }   from './../../bbjs/bbj';
//import { BBJService}     from './../../bbjs/bbj.service';

//import 'rxjs/add/operator/switchMap';
import { Observable } from 'rxjs/Observable';
import { startWith } from 'rxjs/operators/startWith';
import { map } from 'rxjs/operators/map';
import { MainService } from '../../main.service';
import { AlertService } from './../../../alert.service';
import { MatTabChangeEvent, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataloggerHardwareService } from '../../dataloggerHardwares/dataloggerHardware.service';
import { Setting } from '../../settings/setting';
import { MasterSetting } from '../../masterSettings/masterSetting';
import { AuthService } from '../../../auth.service';
import { TabStore } from '../../shared/store/tab.store';
import { Account } from '../../accounts/account';
import { AppService } from '../../apps/app.service';
import { ViewService } from '../../views/views.service';
import { AppView } from '../app-view.interface';
import { EntityModelService } from '../../entityModels/entityModel.service';
import { DataloggerSoftwareService } from '../../dataloggerSoftwares/dataloggerSoftware.service';
import { Metric } from '../../metrics/metric';

//declare var _:any;

export interface MessageHeder {text: string, title: string, style: {}, class: string, type: string};

@Component({
  selector: 'app-entity-edit',
  templateUrl: './entity-edit.component.html',
  styleUrls: ['./entity-edit.component.css'],
})
export class EntityEditComponent implements OnInit {

  errorMessage: string;
  entity: Entity;
  entityModels: EntityModel[] = [];
  dataloggerHardwares: DataloggerHardware[] = [];
  dataloggerSoftwares: DataloggerSoftware[] = [];
  dataloggerSoftwaresAll: DataloggerSoftware[] = [];
  dataloggerHardware: DataloggerHardware;
  masterSettings: MasterSetting[];
  entitySettings: Setting[];
  datasources: Datasource[];
  metrics: Metric[];
  //source: Source = new Source (null, null, null, null);
  //selectedDataloggerHardware: DataloggerHardware;
  myControl: FormControl = new FormControl();
  viewControl: FormControl = new FormControl();
  //filteredDataloggerHardwares: Observable<DataloggerHardware[]>;
  @Output() editMode: boolean = false;
  module: string = 'Machine';
  entities: boolean = true;
  new: boolean = true;
  cardHeaderState: string;
  initialCardHeaderState: string;
  isAdmin: boolean = false;
  isEndUser: boolean = false;
  isProvider: boolean = false;
  state: any;
  today: Date = new Date();
  variablesLoad: boolean = false;
  subscriptions: Array<any> = [];
  hasActiveLicense: boolean = false;
  hasExpiredLicense: boolean = false;
  hasEvaluationLicense: boolean = false;
  formatedStart: any;
  locationString: string;
  canCreate: boolean = true;
  currentTabIndex: number;
  componentName = this.module;
  url = this._location.path(false).split(/[?#]/)[0];
  tabStateId = {
    url: this.url,
    component: this.componentName
  };
  showCalendar = false;
  canAccessReports: boolean = false;
  canEditEntityData: boolean = false;

  workingDatasources = [];
  failingDatasources = [];

  workingDatasourceCount = 0;
  failingDatasourceCount = 0;

  allDatasourcesWorking = false;
  allDatasourcesFailing = false;

  tooltipText = "";
  loadingDatasourcesStatus = true;

  activeLicense: any;

  viewsEnabled: boolean = false;
  apps = [];
  apps_views: AppView[] = [];
  entityApps: number[] = [];
  entityViews: number[] = [];

  entityUpdated: boolean;

  private orgDescription: string;

  @ViewChild('f_input')
  f_input: ElementRef;  

  uploadingZip: boolean = false;
  fileUploaded: string = "There is no last file uploaded yet.";
  idOrganization: number = 0;

  showMessageHeader: MessageHeder[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private entityService: EntityService,
    private dataloggerHardwareService: DataloggerHardwareService,
    //private bbjService: BBJService,
    private _location: Location,
    private mainService: MainService,
    private alertService: AlertService,
    private authService: AuthService,
    private tabStore: TabStore,
    private dialog: MatDialog,
    private appsService: AppService,
    private viewService: ViewService,
    private entityModelService: EntityModelService,
    private dataloggerSoftwareService: DataloggerSoftwareService
  ) {
    this.router.events
      .filter(event => event instanceof NavigationStart)
      // .map(() => this.router.routerState.root)
      .first()
      .subscribe((event) => {
        this.destroySubscriptions();
      });
  }

  ngOnInit() {
    this.authService.getAccount().subscribe(async (account) => {
      const role = account.idRole;
      this.orgDescription = account.organization.description;
      this.idOrganization = account.organization.idOrganization;
      this.cardHeaderState = 'duplicate';
      this.initialCardHeaderState = 'duplicate';
      if (role == 1) { this.isAdmin = true }
      else if (role == 2) { this.isProvider = true }
      else if (role == 3) {
        this.cardHeaderState = 'onlyEdit';
        this.isEndUser = true;
        this.initialCardHeaderState = 'onlyEdit'; this.canCreate = false;
      }
      this.currentTabIndex = this.tabStore.getTabIndex(this.tabStateId);
      if (!this.currentTabIndex) {
        this.currentTabIndex = 0;
        this.tabStore.addTab(this.tabStateId, this.currentTabIndex);
      } else if (this.currentTabIndex == 1) {
        this.variablesLoad = true;
      }

      this.route.data
      .subscribe(async(data: {
        entity: Entity, entitySettings: Setting[], masterSettings: MasterSetting[], datasources: Datasource[], metrics: Metric[]
      }) => {
        if (data.entity) {
          this.entity = data.entity;
          this.metrics = data.metrics ;
          const idOrganization = this.entity['dataloggerSoftware'].dataloggerHardware.idOrganization;
          this.entityModels = await this.getEntityModelsOrganization(idOrganization.toString());
          this.dataloggerHardwares = await this.getOrganizationDataloggerHardwares(idOrganization);
          this.dataloggerSoftwares = await this.getOrganizationDataloggerSoftwares(idOrganization);
          let dataSoft = this.dataloggerSoftwares.find(value => value.idDataloggerSoftware === data.entity.idDataloggerSoftware);
          this.dataloggerHardware = this.dataloggerHardwares.find(value => value.idDataloggerHardware === dataSoft.idDataloggerHardware);
          this.dataloggerHardware["status"] = -1;

          this.showMessageHeader = [];
          if (this.metrics) {
            const allMetricsOk = await this.checkAllMetricsOkAndSetIcon();
            let message: MessageHeder = {
              text: '', 
              title: '', 
              style: {'background-color': 'white','border-radius': '50em', 'font-size': '30px', 'width': '30px', 'height': '30px'}, 
              class: '', 
              type: 'icon'
            };
            if (allMetricsOk) {
              message.text = 'check_circle';
              message.class = 'green-color';
              message.title = 'All metrics are correct';
            } else {
              message.text = this.metrics['rows'][0].metricDown === true ? 'error' : 'cancel_presentation';
              message.class = this.metrics['rows'][0].metricDown === true ? 'orange-color' : 'red-color';
              message.title = this.metrics['rows'][0].metricDown === true ? 'Metrics are out of date, machine is probably offline, otherwise contact Vixion.' : 'At least one metric is not correct';
            }
            this.showMessageHeader.push(message);
          }
          
          if (this.entity.entityInfo.updateDataEdge && !this.entity.entityInfo.updateDataEdge.update) {
            const message = {
              text: `Updated data (${this.entity.entityInfo.updateDataEdge.date})`,
              title: '',
              class: '',
              style: {'background-color': '#348200', 'border': '2px solid white'},
              type: 'text'
            };
            this.showMessageHeader.push(message);
          } else if (this.entity.entityInfo.updateDataEdge && this.entity.entityInfo.updateDataEdge.update) {
            const message = {
              text: `Data pending update (${this.entity.entityInfo.updateDataEdge.date})`,
              title: '',
              class: '',
              style: {'background-color': '#f6a821', 'border': '2px solid white'},
              type: 'text'
            };
            this.showMessageHeader.push(message);
          }

          if (!this.entity.entityInfo.hourlyPrice || !this.entity.entityInfo.oeeObjective) {
            this.entityService.getEntityInfoTypes().subscribe((res: any) => {
              const defaultInfoTypes = res;
              if (!this.entity.entityInfo.hourlyPrice) {
                const defaultHourlyPrice = defaultInfoTypes.find(x => x.type === "hourlyPrice").blueprint.hourlyPrice;
                this.entity.entityInfo["hourlyPrice"] = { hourlyPrice: defaultHourlyPrice };
              }
              if (!this.entity.entityInfo.oeeObjective) {
                const defaultHourlyPrice = defaultInfoTypes.find(x => x.type === "oeeObjective").blueprint.oeeObjective;
                this.entity.entityInfo["oeeObjective"] = { oeeObjective: defaultHourlyPrice };
              }
            });
          }

          this.locationString = this.entity.entityInfo.location ? this.entity.entityInfo.location.lat + ', ' + this.entity.entityInfo.location.lng : null;
          this.state = ({
            entity: data.entity
          });

          this.entitySettings = data.entitySettings['rows'];
          this.datasources = data.datasources['rows'];

          if (this.entity['licenses'].length > 0) {
            this.activeLicense = _.find(this.entity['licenses'], function (e) { return e.entity_license.active == true && e.entity_license.expired == false; });
            if (this.activeLicense) {
              this.formatedStart = moment(this.activeLicense.entity_license.start, "YYYY-MM-DD").format("DD/MM/YYYY");
            }
          }

          switch (this.entity["license"].status) {
            case 1:
              this.hasActiveLicense = true;
              break;
            case 2:
              this.hasActiveLicense = true;
              this.hasEvaluationLicense = true;
              break;
            case 3:
              this.hasActiveLicense = false;
              this.hasExpiredLicense = true;
              break;
            default:
              break;
          }

          this.getAppsAndViews();

          this.checkCalendarAccess(account);
          this.checkReportsAccess();

        } else {
          this.entity = new Entity(null, null, null, null, null, null, null, null, null, { location: { lat: null, lng: null }, hourlyPrice: { hourlyPrice: null }, oeeObjective: { oeeObjective: null } }, null, null);
          this.editMode = true;
          this.new = false;
          this.cardHeaderState = 'new';
        }
        
        this.masterSettings = data.masterSettings['rows'];

        if (this.dataloggerHardware) {
          this.getLastUploadedFile(this.dataloggerHardware.serialNumber);
        }

      });
    });
  }

  private checkAllMetricsOkAndSetIcon():boolean {
    let metricOk = true;
    for (let i = 0; i < this.metrics['rows'].length; i++) {
      const metric = this.metrics['rows'][i];
      metric.icon = {icon: '', color: '', title: ''}
      if (metric.metricDown === true) {
        metric.icon.icon = 'error';
        metric.icon.color = 'orange-color';
        metric.icon.title = metric.metricValue === '0' ? 'The last metric is not correct, but metrics are out of date, machine is probably offline' : 'The last metric is correct, but metrics are out of date, machine is probably offline';
      } else {
        metric.icon.icon = metric.metricValue === '0' ? 'cancel_presentation' : 'check_circle';
        metric.icon.color = metric.metricValue === '0' ? 'red-color' : 'green-color';
        metric.icon.title = metric.metricValue === '0' ? 'The last metric is not correct' : 'The last metric is correct';
      }
      if ((metric.metricValue === '0' || metric.metricDown === true) && metricOk) {
        metricOk = false;
      }
    }
    return metricOk;
  }

  getEntityModelsOrganization(idOrganization: string):Promise <EntityModel[]> {
    return new Promise<EntityModel[]>((resolve,reject)=>{
      this.entityModelService.getHierarchyOrganizationEntityModels(idOrganization, null, 0, 'name', 'asc', '').subscribe(res => {
        resolve(res['rows']);
      });
    })
  }

  getOrganizationDataloggerHardwares(idOrganization: string):Promise <DataloggerHardware[]> {
    return new Promise<DataloggerHardware[]>((resolve,reject)=>{
      this.dataloggerHardwareService.getOrganizationDataloggerHardwares(idOrganization, null, 0, 'serialNumber', 'asc', '').subscribe(res => {
        resolve(res['rows']);
      });
    })
  }

  getOrganizationDataloggerSoftwares(idOrganization: string):Promise <DataloggerSoftware[]> {
    return new Promise<DataloggerSoftware[]>((resolve,reject)=>{
      this.dataloggerSoftwareService.getOrganizationDataloggerSoftwares(idOrganization, null, 0, '', '', '').subscribe(res => {
        resolve(res['rows']);
      });
    })
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // this.dataloggerHardwares.map( (dataloggerHardware) => {
      if (this.dataloggerHardware) {
        this.subscriptions.push(this.dataloggerHardwareService.getDataloggerHardwareStatus(this.dataloggerHardware).subscribe(
          res => {
            this.dataloggerHardware["status"] = res;
          }
        ));

        this.subscriptions;
      }
    });
  }

  getLastUploadedFile(dataLoggerHardwareSerialNumber) {

    this.dataloggerHardwareService.getDataloggerHardwareLastFileCustom(dataLoggerHardwareSerialNumber.toString()).subscribe(
      (response) => {
        const lastFileEntity = _.find(response, ['entity', this.entity.idEntity]);
        this.fileUploaded = lastFileEntity.lastFile;
      }, 
      (err) => {

        if (err.status === 404) {

          this.fileUploaded = "Last file uploaded not found.";
        } else {
          this.fileUploaded = "There was an error during loading last uploaded file.";
        }
      }
    );
  }

  checkCalendarAccess(account: Account): void {
    this.canEditEntityData = this.entity["dataloggerSoftware"].dataloggerHardware.organization.hierarchy.includes('/-' + account.idOrganization + '-/');
    this.showCalendar = this.entity["dataloggerSoftware"].dataloggerHardware.organization.hierarchy.includes('/-' + account.idOrganization + '-/');
  }

  checkReportsAccess() {
    this.entityService.canAccessReports(this.entity.idEntity).subscribe((res) => {
      this.canAccessReports = res.canAccessReports || this.isAdmin;
    });
  }

  destroySubscriptions() {
    this.subscriptions.map(subscription => subscription.unsubscribe());
  }

  onheaderActionEmitter(message: any): void {
    switch (message.text) {
      case 'edit':
        this.editMode = !this.editMode;

        // this.onNew();
        break;
      case 'save':
        this.onSave();
        // this.onSelect(message.data);
        this.editMode = !this.editMode;
        break;
      case 'cancel':
        // this.onEdit(message.data);
        this.editMode = !this.editMode;
        break;
      case 'remove':
        this.onDelete();
        // this.onRemove(message.data);
        break;
      case 'generateBBJ':
        this.onGenerateBBJ();
        // this.onRemove(message.data);
        break;
      case 'duplicate':
        this.onDuplicate();
        // this.onRemove(message.data);
        break;
      case 'back':
        this.onCancel();
        break;
    }
  }

  getAppsAndViews(): void {
    // get all available apps
    this.appsService.getLicensedApps(this.entity.idEntity.toString()).subscribe((res: any) => {
      if (!res['license']) {
        // no active license disable combo and show message
        this.viewsEnabled = false;
      } else {
        this.viewsEnabled = true;
        this.apps = res['rows'];
        for (let app of this.apps) {
          const app_elem = {
            idApp: app.idApp,
            description: app.name,
            elem: "app"
          }
          this.apps_views.push(app_elem);
          for (let view of app.views) {
            const view_elem = {
              idApp: app.idApp,
              idView: view.idView,
              description: view.name.substr(view.name.lastIndexOf('.') + 1),
              elem: "view"
            }
            this.apps_views.push(view_elem);
          }
        }

        // get current entity apps
        this.appsService.getEntityApps(this.entity.idEntity.toString()).subscribe((res: any) => {
          const restrictedApps = [];
          for (let app of res['rows']) {
            restrictedApps.push(app.idApp);
          }
          const apps = this.apps.map(x => x.idApp).filter(x => !restrictedApps.includes(x));
          this.entityApps = apps;

          // get current entity views
          this.viewService.getEntityViews(this.entity.idEntity.toString()).subscribe((res: any) => {
            const restrictedViews = [];
            for (let view of res['rows']) {
              restrictedViews.push(view.idView);
            }
            let views = [];
            this.apps.forEach(app => {
              views = views.concat(app.views.map(x => x.idView));
            });
            views = views.filter(x => !restrictedViews.includes(x));
            this.entityViews = views;

            // set values
            const entApps = this.apps_views.filter(app => app.elem === "app").filter(x => this.entityApps.includes(x.idApp));
            const entViews = this.apps_views.filter(app => app.elem === "view").filter(x => this.entityViews.includes(x.idView));
            this.viewControl.setValue(entApps.concat(entViews));
          });
        });
      }
    });
  }

  viewSelectionChange(event, view) {
    if (event.isUserInput) {
      let currentValues = this.viewControl.value || [];
      if (view.elem === "app") {
        if (!currentValues.map(val => val.idApp).includes(view.idApp)) {
          // selected app
          const addedValues = this.apps_views.filter(x => x.idApp === view.idApp).filter(y => !currentValues.includes(y));
          currentValues = currentValues.concat(addedValues);
          this.viewControl.setValue(currentValues);
        } else {
          // unselected app
          currentValues = currentValues.filter(x => x.idApp !== view.idApp);
          this.viewControl.setValue(currentValues);
        }
      } else if (view.elem === "view") {
        if (!currentValues.filter(val => val.elem === "view").map(val => val.idView).includes(view.idView)) {
          // selected view
          if (!currentValues.map(val => val.idApp).includes(view.idApp)) {
            const selectedApp = this.apps_views.find(x => (x.idApp === view.idApp && x.elem === "app"));
            currentValues.push(selectedApp);
          }
          const selectedView = this.apps_views.find(x => x.idView === view.idView);
          currentValues.push(selectedView);
          this.viewControl.setValue(currentValues);
        } else {
          // unselected view
          currentValues = currentValues.filter(x => x.idView !== view.idView);
          const appViews = this.apps.find(x => x.idApp === view.idApp).views.map(view => view.idView);
          if (!currentValues.filter(val => val.elem === "view").map(val => val.idView).some(x => appViews.includes(x))) {
            currentValues = currentValues.filter(x => x.idApp !== view.idApp);
          }
          this.viewControl.setValue(currentValues);
        }
      }
    }
  }

  filter(val: any): DataloggerHardware[] {
    return this.dataloggerHardwares.filter(dataloggerHardware =>
      dataloggerHardware.serialNumber.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  onCancel(): void {
    if (this.viewsEnabled) {
      const apps = this.apps_views.filter(val => val.elem === "app").filter(val => this.entityApps.includes(val.idApp));
      const views = this.apps_views.filter(val => val.elem === "view").filter(val => this.entityViews.includes(val.idView));
      this.viewControl.setValue(apps.concat(views));
    }

    this._location.back();
  }

  onSave(): void {
    if (this.validateTags()) {
      if (this.viewsEnabled) {
        // add apps and views
        this.entityApps = this.viewControl.value.filter(val => val.elem === "app").map(app => app.idApp);
        this.entityViews = this.viewControl.value.filter(val => val.elem === "view").map(view => view.idView);

        // guardar en la DDBB las apps excluidas
        const restrictedApps = this.apps_views.filter(app => app.elem === "app").filter(x => !this.entityApps.includes(x.idApp)).map(app => app.idApp);
        const restrictedViews = this.apps_views.filter(app => app.elem === "view").filter(x => !this.entityViews.includes(x.idView)).map(view => view.idView);

        this.entity['apps'] = restrictedApps;
        this.entity['views'] = restrictedViews;
      }

      if (this.entity.tags != null && this.entity.tags != '') {
        let tags_splitted = this.entity.tags
          .split(',')
          .map(tag => tag.trim())
          .filter(tag => tag != '' && tag != null);
        this.entity.tags = tags_splitted.length ? tags_splitted.sort((a,b) => a.localeCompare(b)).join(',') : null;
      } else {
        this.entity.tags = null;
      }

      this.entityService.saveEntity(this.entity)
        .subscribe(
          response => {
            this._location.back();
          },
          error => {
            this.editMode = !this.editMode;
            this.errorMessage = <any>error
          }
        );
    } else {
      this.editMode = !this.editMode;
    }
  }

  validateTags(): boolean {
    let valid = true;

    if (this.entity.tags != null && this.entity.tags != '') {
      const regExp = new RegExp('^[a-zA-Z0-9_-]*$');
      const tags_splitted = this.entity.tags
        .split(',')
        .map(tag => tag.trim())
        .filter(tag => tag != '' && tag != null);
      valid = tags_splitted.every(tag => regExp.test(tag));
    }

    return valid;
  }

  composeEntityModel(entityModel: EntityModel): string {
    return entityModel.name + ' - ' + entityModel["organization"].name
  }

  onGenerateBBJ(): void {
    this.entityService.generateEntityConfig(this.entity.idEntity)
      .subscribe(
        response => {
          this.alertService.emitErrorMessage({ text: 'The Vixion Edge has been updated', type: 'success' });
        },
        error => {
          this.alertService.emitErrorMessage({ text: error.error, type: 'danger' });
        });
  }

  assignEntityToLicense() {
    // this.router.navigate(['/entities/new']);
    // this.router.navigate(['/licenses/']);
    this.router.navigate(['/licenses/entity'], { queryParams: { idEntity: this.entity.idEntity } });
  }

  changeToFullLicense() {
    // this.router.navigate(['/entities/new']);
    // this.router.navigate(['/licenses/']);
    this.router.navigate(['/licenses/confirm', this.activeLicense.entity_license.idEntityLicense]);
  }

  onDelete(): void {
    this.entityService.removeEntity(this.entity.idEntity)
      .subscribe(
        response => {
          this._location.back();
        },
        error => this.errorMessage = <any>error);
  }

  onDuplicate(): void {
    this.mainService.setState(this.state);
    this.router.navigate([`/entities/duplicate`]);
  }

  goToVixionEdge(dataloggerHardware: DataloggerHardware): void {
    this.router.navigate(['/dataloggerHardwares/edit', this.dataloggerHardware.idDataloggerHardware]);
  }

  goToLicense(idLicense: number): void {
    this.router.navigate(['/licenses/edit', idLicense]);
  }

  onLocationClick() {
    let dialogRef = this.dialog.open(MapDialogComponent, {
      data: { location: this.entity.entityInfo ? this.entity.entityInfo.location : undefined }
    });
    dialogRef.afterClosed().subscribe(res => {
      this.entity.entityInfo.location = res ? res : this.entity.entityInfo.location;
      this.locationString = this.entity.entityInfo.location ? this.entity.entityInfo.location.lat + ', ' + this.entity.entityInfo.location.lng : this.locationString;
    });
  }

  onTabClick(event) {
    // If variables signals
    if (event.index == 1) {
      this.variablesLoad = true;
    }
    this.tabStore.setTabIndex(this.tabStateId, event.index);
  }

  onDatasourceStatusEmitter(event) {

    this.tooltipText = "";
    this.loadingDatasourcesStatus = false;
    this.workingDatasources = [];
    this.failingDatasources = [];
    this.workingDatasourceCount = 0;
    this.failingDatasourceCount = 0;
    this.allDatasourcesWorking = false;
    this.allDatasourcesFailing = false;

    if (event.length > 0) {
      for (let datasource of event) {
        if (datasource.status.status == 1) {
          this.workingDatasources.push(`${datasource.datasource.address} - ${datasource.datasource.datasourceModel.name}`);
          this.workingDatasourceCount++;
        } else {
          this.failingDatasources.push(`${datasource.datasource.address} - ${datasource.datasource.datasourceModel.name}`);
          this.failingDatasourceCount++;
        }
      }

      if (this.workingDatasourceCount > 0) {
        // if (this.workingDatasourceCount == 0) {
        //   this.tooltipText = "Working:\nNone.";
        // } else {
        this.tooltipText = `Working:\n* ${this.workingDatasources.join('\r\n* ')}`;
        // }

        if (this.workingDatasourceCount == event.length) {
          this.allDatasourcesWorking = true;
        }

        if (this.failingDatasourceCount >= 0) {
          this.tooltipText = `${this.tooltipText}\n`;
        }
      }

      if (this.failingDatasourceCount > 0) {
        // if (this.failingDatasourceCount == 0) {
        //   this.tooltipText += `Failing:\nNone.`;
        // } else {

        this.tooltipText += `Failing:\n* ${this.failingDatasources.join('\r\n* ')}`;
        // }

        if (this.failingDatasourceCount == event.length) {
          this.allDatasourcesFailing = true;
        }
      }

    } else {
      this.tooltipText = "No datasources available"
    }
  }

  public isOrganization(name: string) {
    if (name === this.orgDescription || this.isAdmin) {
      // FIXME: only for development
      return true;
    }
    return name === this.orgDescription;
  }

  // openDialog(): void {
  //   let dialogRef = this.dialog.open(DialogComponent);
  //
  //   dialogRef.afterClosed().subscribe(result => {import { MapDialogComponent } from ''
  //     if(result) this.onDelete();
  //   });
  // }

  //onControlModelChange(event: any): void {
  //this.bbj.MonitorConfig.sources = JSON.parse(this.controlModels[event.value - 1].datasourceParams);
  //this.source.address = ""; //to force to update field
  //if (!_.isEmpty(this.bbj.MonitorConfig.sources)) {
  //this.datasource_name = _.first(Object.keys(this.bbj.MonitorConfig.sources));
  //this.source = this.bbj.MonitorConfig.sources[this.datasource_name];
  //}
  //}

  handleFileInputChange(files: FileList): void {
    this.uploadingZip = true;
    const formData = new FormData(); 
    formData.append("file", files[0], files[0].name);
    formData.append("idDataloggerHardware", this.dataloggerHardware.idDataloggerHardware.toString());
    formData.append("serialNumberDataloggerHardware", this.dataloggerHardware.serialNumber.toString());
    formData.append("idEntity", this.entity.idEntity.toString())

    this.entityService.uploadSqliteZip(this.entity.idEntity, formData).subscribe(
      response => {
        this.alertService.emitErrorMessage({text: 'Zip uploaded succesfully', type: 'info'});
        this.uploadingZip = false;
        this.getLastUploadedFile(this.dataloggerHardware.serialNumber);
      },
      error => {
        this.alertService.emitErrorMessage({text: 'Zip upload failed', type: 'error'});
        this.f_input.nativeElement.value = "";
        this.uploadingZip = false;
      },
      (() => {

        this.f_input.nativeElement.value = "";
        this.uploadingZip = false;
      })
    );
  }


}

// //DIALOG COMPONENT
//
@Component({
  selector: '[mapdialog]',
  template: `
    <h2 mat-dialog-title>Select the location of the machine</h2>
    <mat-dialog-content><div id="map" style="width:1000px; height:500px;"></div></mat-dialog-content>
    <mat-dialog-actions align="end">
      <form [formGroup]="mapForm" style="width:100%;" (ngSubmit)="save(location)">
        <mat-form-field style="width:50%;">
          <input matInput type="number" id="latitude" name="latitude" placeholder="Latitude" formControlName="latitude"
            (input)="onInputChange()">
        </mat-form-field>
        <mat-form-field align="end" style="width:50%;">
          <input matInput type="number" id="longitude" name="longitude" placeholder="Longitude" formControlName="longitude"
            (input)="onInputChange()">
        </mat-form-field>
        <button mat-button type="button" (click)="cancel()">CANCEL</button>
        <button mat-button type="submit" style="justify-content: flex-end;">SAVE</button>
      </form>
    </mat-dialog-actions>`
})

export class MapDialogComponent implements OnInit {

  map: any;
  icon: any;
  marker: any;
  location: { lat: string, lng: string };

  mapForm: FormGroup;

  constructor(private dialogRef: MatDialogRef<MapDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.location = this.data.location ? this.data.location : { lat: null, lng: null };
  }

  ngOnInit() {
    // Initialize the form
    this.mapForm = this.fb.group({
      latitude: [this.location.lat, [Validators.required]],
      longitude: [this.location.lng, [Validators.required]]
    });

    // Create the map
    this.map = L.map('map').setView([this.location.lat ? this.location.lat : 0, this.location.lng ? this.location.lng : 0], 2);
    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
    var options = {
      collapsed: false,
      defaultMarkGeocode: false
    };
    /* L.Icon.Default.prototype.options = {
      iconUrl: 'assets/images/red_marker.png',
      iconSize: 30
    }; */
    this.icon = new L.Icon({
      iconUrl: 'assets/images/red_marker.png',
      iconSize: 30
    });
    if (this.location.lat && this.location.lng) {
      this.marker = L.marker([this.location.lat, this.location.lng], { icon: this.icon }).addTo(this.map);
      this.map.setView(this.location, 15);
    }
    // Geocoder controller for searching places in the map
    L.Control.geocoder(options).on('markgeocode', (e: any) => {
      this.map.fitBounds(e.geocode.bbox);
    })
      .addTo(this.map);

    // On click we add the marker to the map, get the position of it, and put the coordinates on the input
    this.map.on('click', (e: any) => {
      this.mapForm.get('latitude').setValue(e.latlng.lat);
      this.mapForm.get('longitude').setValue(e.latlng.lng);
      this.setMapPosition(e.latlng);
    });
  }

  onInputChange() {
    this.setMapPosition({ lat: this.mapForm.get('latitude').value, lng: this.mapForm.get('longitude').value });
  }

  /**
   * Function that sets the position of the marker in the map
   * @param location 
   */
  setMapPosition(location: any) {
    this.map.setView(location);
    this.location = { lat: location.lat, lng: location.lng };
    if (!this.marker) {
      this.marker = L.marker(location, { icon: this.icon }).addTo(this.map);
    }
    this.marker.setLatLng(location);
  }

  /**
   * Function that saves the selected location and closes the dialog window
   * @param location
   */
  save(location: any) {
    if (this.mapForm.valid) {
      location.lat = location.lat.toString();
      location.lng = location.lng.toString();
      this.dialogRef.close(location);
    }
  }

  /**
   * Function that closes the dialog window
   */
  cancel() {
    this.dialogRef.close();
  }

}
