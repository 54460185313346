import { Component } from '@angular/core';
import { Router, NavigationEnd }    from '@angular/router';
import { environment } from './../environments/environment';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  //title = 'app works!';
  //
  private routerSubscription;

  constructor(private router: Router, private authService: AuthService) {
    // if (environment.production) {
    //   authService.getAccount().then((account) => {
    //     if (account) {
    //       (<any>window).gtag('set', {'user_id': account.username})
    //     }
    //     this.router.events.subscribe(event => {
    //       if (event instanceof NavigationEnd) {
    //         (<any>window).gtag('config', 'UA-121636164-1', {
    //           'page_path': event.urlAfterRedirects
    //         });
    //       }
    //     });
    //   });
    // }
  }
}
