import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { EntityModel }      from './entityModel';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class EntityModelService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all entityModels
   */
  public getEntityModels(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<EntityModel[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<EntityModel[]>(environment.api_url + '/entityModels', { params: queryParams });
  };

  /**
   * Retrieves entityModel details by a given id
   *
   * @param idEntityModel
   */
  public getEntityModel(idEntityModel: string): Observable<EntityModel> { 
    return this.http.get<EntityModel>(environment.api_url + '/entityModel/' + idEntityModel)
  };

  /**
   * Save entityModel
   *
   * @param entityModel
   */
  //public saveEntityModel(entityModel: EntityModel): Observable<Response> { 
  public saveEntityModel(entityModel: EntityModel): Observable<EntityModel> { 
    if (entityModel.idEntityModel) {
      return this.updateEntityModel(entityModel);
    } else {
      return this.newEntityModel(entityModel);
    }
    //

  };

  /**
   * Create new entityModel
   *
   * @param entityModel
   */
  public newEntityModel(entityModel: EntityModel): Observable<EntityModel> { 
    return this.http.post<EntityModel>(environment.api_url + "/entityModel", entityModel);
  };
  
  /**
   * Update entityModel
   *
   * @param entityModel
   */
  public updateEntityModel(entityModel: EntityModel): Observable<EntityModel> { 
    return this.http.put<EntityModel>(environment.api_url + "/entityModel", entityModel);
  };
  
  /**
   * Delete an entityModel by a given id
   *
   * @param idEntityModel 
   */
  public removeEntityModel (idEntityModel: number): Observable<any> { 
    return this.http.delete<EntityModel[]>(environment.api_url + '/entityModel/' + idEntityModel );
  }

 /**
   * Retrieves all entityModels
   */
  public getOrganizationEntityModels(idOrganization: string, limit: number, offset: number, sort: string, direction: string, filter: string): Observable<EntityModel[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<EntityModel[]>(environment.api_url + '/entityModels/organization/' + idOrganization, { params: queryParams });
  };
 /**
   * Retrieves all entityModels
   */
    public getHierarchyOrganizationEntityModels(idOrganization: string, limit: number, offset: number, sort: string, direction: string, filter: string): Observable<EntityModel[]> { 
      let queryParams = new HttpParams();
      if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
      if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
      if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
      if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
      if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));
  
      return this.http.get<EntityModel[]>(environment.api_url + '/entityModels/hierarchy/organization/' + idOrganization, { params: queryParams });
    };
}
