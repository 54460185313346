import { Component,
         Inject,
         Input,
         Output,
         EventEmitter }    from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, getMatInputUnsupportedTypeError } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { Account } from '../../accounts/account';

declare var _:any;

@Component({
  selector: 'cardHeader-edit',
  templateUrl: './cardHeader-edit.component.html',
  styleUrls: ['./cardHeader-edit.component.css']
})
export class CardHeaderEditComponent {


  constructor(
    public dialog: MatDialog
  ){}
  @Input() editMode: boolean = false;
  @Input() isBeta: boolean = false;
  @Input() hideBackButton: boolean = false;
  // @Input() newMode: boolean = false;
  // @Input() deleteMode: boolean = false;

  @Input() messages: {text: string, title: string, style: string, class: string, type: string}[];

  @Input() module: string;
  @Input() entities: boolean;
  @Input() accountAppNew: boolean ;
  @Input() cardHeaderState: string;
  @Input() initialCardHeaderState: string;
  @Output() headerActionEmitter: EventEmitter<any> = new EventEmitter<any>();

  cardHeaderColor = environment.card_header_color;

  // tableDatabase: any;
  // dataSource: TableDataSource  | null;
  // removableItem: any;
  // displayedColumns: any;
  // tableLength: number = 0;

  // ngOnInit() {
  //   this.initialCardHeaderState = this.cardHeaderState;
  // }

  //ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
  //}

  // ngAfterContentChecked(){
    // if(this.values && !this.dataSource){
    //   this.tableLength = this.values.length;
    //   this.tableDatabase = new TableDatabase(this.values);
    //   this.dataSource = new TableDataSource(this.tableDatabase, this.paginator, this.sort);
    // }
  // }

  getValue(value: Object, field: string){
    return _.get(value, field);
  }

  onNew() {
    // this.editMode = true;
  }

  onCancel(){
    // if (this.entities) {
    //   this.cardHeaderState = 'duplicate';
    // }
    // else if (this.accountAppNew) {
    //   this.cardHeaderState = 'onlyEdit';
    // }
    // else{
    //   this.cardHeaderState = 'edit';
    // }

    this.cardHeaderState = (this.initialCardHeaderState != null) ? this.initialCardHeaderState : 'edit';
    this.headerActionEmitter.emit({text: 'cancel'});
  }

  onEdit(){
    this.cardHeaderState = 'new';
    // this.editMode = true;
    // this.accountNew = false;
    this.headerActionEmitter.emit({text: 'edit'});

  }

  onBack(){
        // this._location.back();
    this.headerActionEmitter.emit({text: 'back'});
  }

  onSave(account: Account): void {
    // this.editMode = !this.editMode;
    // this.cardHeaderState = 'edit';
    this.headerActionEmitter.emit({text: 'save'});

  }

  onDuplicate(): void {
    this.headerActionEmitter.emit({text: 'duplicate'});
  }

  onImpersonate(): void {
    this.headerActionEmitter.emit({text: 'impersonate'});
  }

  onGenerateBBJ(): void {
    let dialogRef = this.dialog.open(DialogComponent,{
        data: { tittle: "Apply configuration", body: " The Vixion Edge will be updated with the current configuration. Are you sure?" }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.headerActionEmitter.emit({text: 'generateBBJ'});
      }

    });
  }

  openDialog(): void {
    let dialogRef = this.dialog.open(DialogComponent,{
        data: { tittle: "Delete",
                body: "Are you sure?"
              }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.headerActionEmitter.emit({text: 'remove'});
      }

    });
  }
}

// //DIALOG COMPONENT
//
@Component({
  selector: '[dialogtest]',
  template: `
    <h2 mat-dialog-title>{{ data.tittle }}</h2>
    <mat-dialog-content> {{ data.body }} </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">No</button>
      <button mat-button [mat-dialog-close]="true">Yes</button>
    </mat-dialog-actions>`
})
export class DialogComponent {

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
