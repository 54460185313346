import { Injectable }             from '@angular/core';
import { Router, Resolve, 
         RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';
import { Observable }             from 'rxjs/Observable';

import { Account }          from './../account';
import { AccountService }   from './../account.service';


@Injectable()
export class AccountAppEditResolver implements Resolve<Account> {
  constructor(private accountService: AccountService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Account> {
    return this.accountService.getAccount(route.params['idAccount']);
  }
}
