import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { DataloggerHardware } from './dataloggerHardware';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class DataloggerHardwareService {
  
  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all dataloggerHardwares
   */
  public getDataloggerHardwares(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<DataloggerHardware[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<DataloggerHardware[]>(environment.api_url + '/dataloggerHardwares', { params: queryParams });
  };
  /**
   * Retrieves dataloggerHardware details by a given id
   *
   * @param idDataloggerHardware
   */
  public getDataloggerHardware(idDataloggerHardware: string): Observable<DataloggerHardware> { 
    return this.http.get<DataloggerHardware>(environment.api_url + '/dataloggerHardware/' + idDataloggerHardware);
  };

  public getFreeDataloggerHardwares(): Observable<DataloggerHardware[]> { 
    return this.http.get<DataloggerHardware[]>(environment.api_url + '/dataloggerHardwares/free');
  };
  /**
   * Save dataloggerHardware
   *
   * @param dataloggerHardware
   */
  public saveDataloggerHardware(dataloggerHardware: DataloggerHardware): Observable<any> { 
    if (dataloggerHardware.idDataloggerHardware) {
      return this.updateDataloggerHardware(dataloggerHardware);
    } else {
      return this.newDataloggerHardware(dataloggerHardware);
    }    
  };

  /**
   * Create new dataloggerHardware
   *
   * @param dataloggerHardware
   */
  public newDataloggerHardware(dataloggerHardware: DataloggerHardware): Observable<any> { 
    return this.http.post(environment.api_url + "/dataloggerHardware", dataloggerHardware)
  };
  
  /**
   * Update dataloggerHardware
   *
   * @param dataloggerHardware
   */
  public updateDataloggerHardware(dataloggerHardware: DataloggerHardware): Observable<any> { 
    return this.http.put(environment.api_url + "/dataloggerHardware", dataloggerHardware)
  };
  
  /**
   * Delete an dataloggerHardware by a given id
   *
   * @param idDataloggerHardware 
   */
  public removeDataloggerHardware (idDataloggerHardware: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/dataloggerHardware/' + idDataloggerHardware);

  }

  
  /**
   * Retrieves dataloggerHardware details by a organization id
   *
   * @param idOrganization
   */
  public getOrganizationDataloggerHardwares(idOrganization: string, limit: number, offset: number, sort: string, direction: string, filter: string): Observable<DataloggerHardware[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<DataloggerHardware[]>(environment.api_url + '/dataloggerHardwares/organization/' + idOrganization, { params: queryParams });
  };
  
  /**
   * Retrieves dataloggerHardware details by a given app id
   *
   * @param dataloggerHardware
   */
  public getDataloggerHardwareStatus(dataloggerHardware: DataloggerHardware): Observable<DataloggerHardware[]> { 
    return this.http.post<DataloggerHardware[]>(environment.api_url + "/dataloggerHardware/status", dataloggerHardware)
  };

  /**
   * Retrieves dataloggerHardware details by a given app id
   *
   * @param idDataloggerHardware
   */
  public getDataloggerHardwareConnectivityStatus(idDataloggerHardware: number): Observable<any> { 
    return this.http.get(environment.api_url + '/dataloggerHardware/conectivityStatus/' + idDataloggerHardware);
  };
  
  getDataloggerHardwareLastFileCustom(serialNumber: string):Observable<any> {
    return this.http.get(environment.api_url + '/dataloggerHardware/' + serialNumber + '/entities/lastFile');
  }

  public getDataloggerHardwareLastFile(serialNumber): Observable<any> { 
    return this.http.get(environment.api_url + '/dataloggerHardware/' + serialNumber + '/entities/lastFile');
  };

}
