export class Organization {
  constructor(
    public idOrganization: number,
    public idRole: number,
    public hierarchy: string,
    public name: string,
    public image: Text,
    public description: string,
    public nameSpace: string) { }
}
