import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { DatasourceModel } from './datasourceModel';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class DatasourceModelService {
  
  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all datasourceModels
   */
  public getDatasourceModels(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<DatasourceModel[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<DatasourceModel[]>(environment.api_url + '/datasourceModels', { params: queryParams });
  };
  /**
   * Retrieves datasourceModel details by a given id
   *
   * @param idDatasourceModel
   */
  public getDatasourceModel(idDatasourceModel: string): Observable<DatasourceModel> { 
    return this.http.get<DatasourceModel>(environment.api_url + '/datasourceModel/' + idDatasourceModel);
  };

  /**
   * Save datasourceModel
   *
   * @param datasourceModel
   */
  public saveDatasourceModel(datasourceModel: DatasourceModel): Observable<any> { 
    if (datasourceModel.idDatasourceModel) {
      return this.updateDatasourceModel(datasourceModel);
    } else {
      return this.newDatasourceModel(datasourceModel);
    }
    //

  };

  /**
   * Create new datasourceModel
   *
   * @param datasourceModel
   */
  public newDatasourceModel(datasourceModel: DatasourceModel): Observable<any> { 
    return this.http.post<DatasourceModel>(environment.api_url + '/datasourceModel', datasourceModel);
  };
  
  /**
   * Update datasourceModel
   *
   * @param datasourceModel
   */
  public updateDatasourceModel(datasourceModel: DatasourceModel): Observable<any> { 
    return this.http.put<DatasourceModel>(environment.api_url + '/datasourceModel', datasourceModel);
  };
  
  /**
   * Delete an datasourceModel by a given id
   *
   * @param idDatasourceModel 
   */
  public removeDatasourceModel (idDatasourceModel: number): Observable<any> { 
    return this.http.delete<DatasourceModel>(environment.api_url + '/datasourceModel/' + idDatasourceModel);
  }


}
