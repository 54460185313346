import { Component,
         OnInit,
         ElementRef,
         ViewChild,
         Inject,
         Output }                                   from '@angular/core';
import { Router, ActivatedRoute, Params }           from '@angular/router';
import { Location }                                 from '@angular/common';
import { FormControl, ReactiveFormsModule }         from '@angular/forms';

import { Account }        from '../account';
import { AccountService } from '../account.service';

import { AlertService }    from './../../../alert.service';

//import 'rxjs/add/operator/switchMap';
import {Observable} from 'rxjs/Observable';
import {startWith}  from 'rxjs/operators/startWith';
import {map}        from 'rxjs/operators/map';

//declare var $:any;

@Component({
  selector: 'account-app-edit',
  templateUrl: './account-app-edit.component.html',
  styleUrls: ['./account-app-edit.component.css'],
  providers: [ AccountService ]

})
export class AccountAppEditComponent implements OnInit {

  errorMessage: string;
  accounts: Account[];
  account: Account;
  selectedAccount: Account;
  myControl: FormControl = new FormControl();
  filteredAccounts: Observable<Account[]>;
  isDisabled: boolean = true;
  module: string = 'Account-app';
  @Output() editMode: boolean = true;
  accountAppNew: boolean = true;
  cardHeaderState: string = 'new';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private _location: Location
  ) { }

  ngOnInit() {
    // Disable delete button if we are editing
    if(this.route.params['_value'].idAccount) {
      this.isDisabled = false;
      this.cardHeaderState= 'delete';
      this.accountAppNew = false;
      // this.editMode = false;
      this.route.data
        .subscribe((data: { account: Account }) => {
          this.account = data.account;
        });
    } else {
      this.route.data
        .subscribe((data: { accounts: Account[] }) => {
          this.accounts = data.accounts;
          this.cardHeaderState = 'new';
          this.filteredAccounts = this.myControl.valueChanges
          .pipe(
            startWith(''),
            map(val => this.filter(val))
          );
        });
    }
  }

  onheaderActionEmitter(message:any):void {
    switch (message.text) {
      case 'edit':
        this.editMode = !this.editMode;
        // this.accountNew = !this.accountNew;
        // this.onNew();
        break;
      case 'save':
        this.onSave();
        // this.onSelect(message.data);
        this.editMode = !this.editMode;
        // this.accountNew = !this.accountNew;
        break;
      case 'cancel':
        // this.onEdit(message.data);
        this.editMode = !this.editMode;
        // this.accountNew = !this.accountNew;
        break;
      case 'remove':
        this.onDelete(this.account);
        // this.onRemove(message.data);
        break;
      case 'back':
        this.onCancel();
        break;
    }
  }

  filter(val: any): Account[] {
    return this.accounts.filter(account =>
      account.username.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  onCancel(): void {
    this._location.back();
  }

  onSave(): void {
    // let selectedAccount = this.accounts.filter(account =>
    //   account.username === this.myControl.value);
    // this.accountService.setAppAccount(this.route.params['_value'].id, selectedAccount[0].idAccount.toString())
    //                     .subscribe(
    //                       response => {
    //                         this._location.back();
    //                       },
    //                       error =>  this.errorMessage = <any>error);
  }

  onDelete(account: Account): void {
    // this.accountService.removeAppAccount(this.route.params['_value'].id, account.idAccount.toString())
    //                     .subscribe(
    //                       response => {
    //                         this._location.back();
    //                       },
    //                       error =>  this.errorMessage = <any>error);
  }

}
