import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Entity }      from './entity';
import { EntityNotification } from './../entityNotifications/entityNotification';
import { MasterVariable } from '../masterVariables/masterVariable';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";


@Injectable()
export class EntityService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all entities
   */
  public getEntities(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Entity[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Entity[]>(environment.api_url + '/entities', { params: queryParams });
  };

  /**
   * Retrieves entity details by a given id
   *
   * @param idEntity
   */
  public getEntity(idEntity: string): Observable<Entity> { 
    return this.http.get<Entity>(environment.api_url + '/entity/' + idEntity);
  };

  /**
 * Retrieves entity details by a given id
 *
 * @param idEntity
 */
  public getEntityExtended(idEntity: string): Observable<Entity> { 
    return this.http.get<Entity>(environment.api_url + '/entity/' + idEntity + '/extended');
  };

  /**
   * Save entity
   *
   * @param entity
   */
  //public saveEntity(entity: Entity): Observable<Response> {
  public saveEntity(entity: Entity): Observable<Entity> {
    if (entity.idEntity) {
      return this.updateEntity(entity);
    } else {
      return this.newEntity(entity);
    }
  };

  /**
   * Create new entity
   *
   * @param entity
   */
  public newEntity(entity: Entity): Observable<Entity> { 
    return this.http.post<Entity>(environment.api_url + '/entity', entity);
  };

  /**
   * Update entity
   *
   * @param entity
   */
  public updateEntity(entity: Entity): Observable<Entity> { 
    return this.http.put<Entity>(environment.api_url + '/entity', entity);
  };

  /**
   * Delete an entity by a given id
   *
   * @param idEntity
   */
  public removeEntity (idEntity: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/entity/' + idEntity);
  }

  /**
   * create entity by a given an entity
   *
   * @param entity
   */
  public duplicateEntity(data: any): Observable<any> { 
    return this.http.post(environment.api_url + '/entities/duplicate', data);
  };


  /**
   * Retrieves entity details by a given id
   * /entity/generateBBJ/:id
   * @param idEntity
   */
  public generateBBJ(idEntity: number): Observable<any>{ 
    return this.http.get(environment.api_url + '/entity/generateBBJ/' + idEntity);
  }

  public generateEntityConfig(idEntity: number): Observable<any>{ 
    return this.http.post(environment.api_url + '/entity/generateEntityConfig/' + idEntity, {});
  }

  /**
   * Retrieves all entities by a given a notificationFilter id
   */
  public getNotificationFilterEntities(idNotificationFilter: number, limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Entity[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Entity[]>(environment.api_url + '/entities/notificationFilter/' + idNotificationFilter, { params: queryParams });
  };

  public getLicenseEntities(idLicense: string, limit: number, offset: number, sort: string, direction: string, filter: string, active: boolean): Observable<Entity[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));
    queryParams = queryParams.set('active', active.toString());

    return this.http.get<Entity[]>(environment.api_url + '/entities/entitiesLicense/' + idLicense, { params: queryParams });
  };
 
  public getFreeEntities(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Entity[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Entity[]>(environment.api_url + '/entities/notAssignedEntities', { params: queryParams });
  };
  
  public getEntitiesByMastervariable(idMasterVariable: number, limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Entity[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Entity[]>(environment.api_url + '/entities/masterVariable/' + idMasterVariable, { params: queryParams });
  };
  public getRealTimeSignalValues(variables: any, idEntity: number): Observable<any> { 
    return this.http.post(environment.api_url + '/entity/realTimeSignalValues/' + idEntity, variables)
  };

  public getEntityMasterVariables(idEntity: number): Observable<MasterVariable[]>{ 
    return this.http.get<MasterVariable[]>(environment.api_url + '/entity/' + idEntity + '/masterVariables');
  }

  public getAddonModelEntities(idAddonModel: number, limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Entity[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Entity[]>(environment.api_url + '/addonModel/' + idAddonModel + '/entities', { params: queryParams });
  };
  /**
   * checks if entity has calendar
   * @param idEntity 
   * @returns 
   */
  public hasYearCalendar (idEntity: number): Observable<any> { 
    return this.http.get(environment.api_url + '/calendar/entity/' + idEntity)
  }
  
  public canAccessReports (idEntity: number): Observable<any> { 
    return this.http.get(environment.api_url + '/canAccessReports/entity/' + idEntity)
  }

  public getEntityInfoTypes(): Observable<any> { 
    return this.http.get(environment.api_url + '/entityInfoTypes')
  };

  uploadSqliteZip(idEntity: number, formData: FormData):Observable<any> {
    console.log('calling to upload file')
    console.log(formData)
    return this.http.post(environment.api_url + '/upload/entity/' + idEntity + '/sqlitezip', formData);
  }

}
