export class Expression {
  constructor(
    public id: string,
    public variable: {
      type: string,
      id: string,
      absolute: Boolean
    },
    public operation: string,
    public value: {
      type: string,
      value: any
    }
  ) { }
}
