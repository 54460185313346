import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { Role }      from './role';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class RoleService {
  
  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all roles
   */
  public getRoles(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<Role[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<Role[]>(environment.api_url + '/roles', { params: queryParams });
  };
  /**
   * Retrieves role details by a given id
   *
   * @param idRole
   */
  public getRole(idRole: string): Observable<Role> { 
    return this.http.get<Role>(environment.api_url + '/role/' + idRole);
  };

  /**
   * Save role
   *
   * @param role
   */
  public saveRole(role: Role): Observable<any> { 
    if (role.idRole) {
      return this.updateRole(role);
    } else {
      return this.newRole(role);
    }
    //

  };

  /**
   * Create new role
   *
   * @param role
   */
  public newRole(role: Role): Observable<any> { 
    return this.http.post(environment.api_url + '/role', role);
  };
  
  /**
   * Update role
   *
   * @param role
   */
  public updateRole(role: Role): Observable<any> { 
    return this.http.put(environment.api_url + '/role', role);
  };
  
  /**
   * Delete an role by a given id
   *
   * @param idRole 
   */
  public removeRole(idRole: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/role/' + idRole);
  }

  /**
   * Retrieves role details by a given app id
   *
   * @param id
   */
  public getAppRoles(idApp: string): Observable<Role[]> { 
    return this.http.delete<Role[]>(environment.api_url + '/roles/app/' + idApp);
  };
  
  /**
   * Add new app to role by a given ids
   *
   * @param idApp
   * @param idRole
   */
  public setAppRole(idApp: string, idRole: string): Observable<any> { 
    return this.http.post(environment.api_url + '/role', {idApp, idRole});
  };
  
  /**
   * Delete an app from role by a given ids
   *
   * @param idApp 
   * @param idRole 
   */
  public removeAppRole(idApp: string, idRole: string): Observable<any> { 
    return this.http.delete(environment.api_url + '/app/' + idApp + '/role/' + idRole);
  }
  
}
