import { Component, OnInit } from '@angular/core';
//import { Location }            from '@angular/common';
import { Router }            from '@angular/router';

import { AuthService }   from './../../auth.service';
import { MainService }   from './../main.service';
import { Role }          from './../roles/role';
import { RoleService }   from './../roles/role.service';
import { AppService }   from './../apps/app.service';
// import { HeaderService } from '../../../../../app/src/app/core/services/header.service';
import { OrganizationService }   from './../organizations/organization.service';
import { App }          from './../apps/app';

import { QueryStore } from '../shared/store/query.store';
import { TabStore } from '../shared/store/tab.store';
import { environment }  from './../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{

  errorMessage: string;
  cols: number = 3;
  apps: any[] = [];
  account : any;
  constructor(
    private appService: AppService,
    private organizationService: OrganizationService,
    private authService: AuthService,
    private queryStore: QueryStore,
    private tabStore: TabStore,
    // private headerService: HeaderService,
  ) { }

  ngOnInit() {
    this.authService.getAccount().subscribe((account) => {
      this.account = account;
      this.appService.getAppsExtended().subscribe(apps => {
        Object.keys(apps).forEach(key => {
          const app = apps[key];
          if ((!app.views || Object.keys(app.views).length === 0)) {
            // Reject the apps that don't have views
            return;
          }
          if (app.name.toLowerCase() === 'documentation') {
            if (this.account.language && this.account.language.code) {
              app.defaultUrl = `https://cdn.vixion360.com/vixion/doc/v2/Vixion_user_manual_${this.account.language.code.toUpperCase()}.pdf`;
            } else {
              app.defaultUrl = `https://cdn.vixion360.com/vixion/doc/v2/Vixion_user_manual_EN.pdf`;
            }
          }

          this.apps.push(app);
        });

        // Max 3 columns of icons in the menu
        this.cols = Math.min(this.apps.length, 3)
      });
    });
  }

  logOut() {
    this.authService.logout();
  }

  resetStateStore(event){
    this.queryStore.deleteAllQueries();
    this.tabStore.deleteAllTabs();
  }

  onAppClick(app) {
    if (app.name.toLowerCase() === 'manager') {
      window.location.reload();
      return;
    }
    let exp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    let url;
    if (app.defaultUrl.match(exp)) {
      url = app.defaultUrl;
    } else {
      /*if (!app.defaultUrl.startsWith('#/')) {
        app.defaultUrl = `#/${app.defaultUrl}`
      }*/

      url = `${environment.app_url}/${app.defaultUrl}`;
      var win = window.open(url, '_blank');
      win.focus();
    }
  }

}
