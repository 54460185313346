import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LanguageListComponent }   from './language-list/language-list.component';
import { LanguageListResolver }    from './language-list/language-list-resolver.service';
import { LanguageEditComponent }   from './language-edit/language-edit.component';
import { LanguageEditResolver }    from './language-edit/language-edit-resolver.service';

const languagesRoutes: Routes = [
  { path: '',
    children: [
      {path: 'language', component: LanguageListComponent },
      {path: 'new', component: LanguageEditComponent },
      {path: 'edit/:id', component: LanguageEditComponent, resolve: {role: LanguageEditResolver} },
      {path: '', component: LanguageListComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(languagesRoutes)],
  exports: [RouterModule],
  providers: [
    LanguageListResolver,
    LanguageEditResolver
  ]
})
export class LanguagesRoutingModule { }
