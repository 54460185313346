import { Component,
         ViewChild,
         ElementRef,
         Input,
         Output,
         EventEmitter }    from '@angular/core';
import { NgSwitch } from '@angular/common';
import { MatPaginator, MatSort }         from '@angular/material';
import { DataSource }      from '@angular/cdk/collections';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable }      from 'rxjs/Observable';

import 'rxjs/add/operator/startWith';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/observable/fromEvent';

import { environment } from '../../../../environments/environment';

declare var _:any;

@Component({
  selector: 'tableList',
  templateUrl: './tableList.component.html',
  styleUrls: ['./tableList.component.css']
})
export class TableListComponent {
  @ViewChild('filter') filter: ElementRef;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Input() module: string;
  @Input() table_headers: Array<string>;
  @Input() fields: Array<string>;
  @Input() values: Array<object>;
  @Input() canCreate: boolean;
  @Input() menuTitle: string;
  @Input() insideEntity: boolean =false;

  @Output() tableListEmitter: EventEmitter<any> = new EventEmitter<any>();

  tableDatabase: any;
  dataSource: TableDataSource  | null;
  removableItem: any;
  displayedColumns: any;
  tableLength: number = 0;
  cardHeaderColor : string;

  ngOnInit() {
    this.cardHeaderColor = environment.card_header_color;
    Observable.fromEvent(this.filter.nativeElement, 'keyup')
        .debounceTime(150)
        .distinctUntilChanged()
        .subscribe(() => {
          if (!this.dataSource) { return; }
          this.dataSource.filter = this.filter.nativeElement.value;
          this.tableLength = this.dataSource.filteredData.length;
        });
  }

  //ngAfterViewInit() {
    //this.dataSource.paginator = this.paginator;
  //}

  ngAfterContentChecked(){
    if(this.values && !this.dataSource){
      this.tableLength = this.values.length;
      this.tableDatabase = new TableDatabase(this.values);
      this.dataSource = new TableDataSource(this.tableDatabase, this.paginator, this.sort);
    }
  }

  getValue(value: Object, field: string){
    return _.get(value, field);
  }

  onNew() {
    this.tableListEmitter.emit({text: 'new'});
  }

  onSelect(event: any, data: any){
    this.tableListEmitter.emit({text: 'select', data: data});
  }

  onEdit(event: any, data: any){
    if (event.stopPropagation) event.stopPropagation();
    this.tableListEmitter.emit({text: 'edit', data: data});
  }

  onRemove(event: any, data: any){
    if (event.stopPropagation) event.stopPropagation();
    //$('#removeModal').modal('show');
    this.removableItem = data;
  }

  onRemoveConfirm(){
    this.tableListEmitter.emit({text: 'remove', data: this.removableItem});
    _.remove(this.values, (value) => value === this.removableItem);
  }
}

/** An example database that the data source uses to retrieve data for the table. */
export class TableDatabase {
  /** Stream that emits whenever the data has been modified. */
  dataChange: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  get data(): Array<any> { return this.dataChange.value; }
  constructor(private values: any) {
    this.dataChange = new BehaviorSubject(values);
  }
}
/**
 * Data source to provide what data should be rendered in the table. The observable provided
 * in connect should emit exactly the data that should be rendered by the table. If the data is
 * altered, the observable should emit that new set of data on the stream. In our case here,
 * we return a stream that contains only one set of data that doesn't change.
 */
export class TableDataSource extends DataSource<any> {
  _filterChange = new BehaviorSubject('');
  get filter(): string { return this._filterChange.value; }
  set filter(filter: string) { this._filterChange.next(filter); }

  filteredData: Array<any> = [];
  renderedData: Array<any> = [];

  constructor(
    private _tableDatabase: TableDatabase, 
    private _paginator: MatPaginator,
    private _sort: MatSort,
  ) { 
    super(); 
    
    this._filterChange.subscribe(() => this._paginator.pageIndex = 0); 
  }
    
  /** Connect function called by the table to retrieve one stream containing the data to render. */
  connect(): Observable<Array<any>> {
    const displayDataChanges = [
      this._tableDatabase.dataChange,
      this._sort.sortChange,
      this._paginator.page,
      this._filterChange
    ];

    return Observable.merge(...displayDataChanges).map(() => {
      // Filter data
      this.filteredData = this._tableDatabase.data.slice().filter((item: any) => {
        let searchStr = this.objToString(item).toLowerCase();
        return searchStr.indexOf(this.filter.toLowerCase()) != -1;
      });

      // Sort filtered data
      //return this.getSortedData(this.filteredData);
      //const sortedData = this.getSortedData(this.filteredData);
      const sortedData = this.getSortedData(this.filteredData.slice());

      // Grab the page's slice of the filtered sorted data.
      const startIndex = this._paginator.pageIndex * this._paginator.pageSize;
      this.renderedData = sortedData.splice(startIndex, this._paginator.pageSize);
      return this.renderedData;
    });
  }

  disconnect() {}

  /** Given an Object, returns a string with the values of the object data */
  private objToString(obj: Object): string {
    return _.reduce(obj, (sum, n) => { 
      if(_.isObject(n)) {
        return sum + this.objToString(n);
      } else {
        if(n) return sum + n;
        else return sum; 
      }
    });
  }

  /** Returns a sorted copy of the database data. */
  getSortedData(data: Array<any>): Array<any> {
    if (!this._sort.active || this._sort.direction == '') { return data; }
    return _.orderBy(data, [this._sort.active], [this._sort.direction]);
  }
}
  
