import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }        from '@angular/forms';
import { MatFormFieldModule,
         MatInputModule,
         MatCardModule,
         MatSelectModule,
         MatRadioModule,
         MatDialogModule,
         MatIconModule,
         MatButtonModule,
         MatDatepickerModule,
         MatNativeDateModule,
         MatAutocompleteModule,
         DateAdapter,
         MatCheckboxModule
        }                     from '@angular/material';

import { ShiftsRoutingModule }  from './shifts-routing.module';
import { ShiftListComponent }   from './shift-list/shift-list.component';
import { ShiftEditComponent}   from './shift-edit/shift-edit.component';
import { ShiftDialog } from './shift-edit/shift-edit.component';
import { ShiftService }         from './shift.service';

import { SharedModule }                 from './../shared/shared.module';
import { CustomDateAdapter } from './custom-date-adapter';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    ShiftsRoutingModule,
    SharedModule
  ],
  entryComponents: [
    ShiftDialog
  ],
  declarations: [
    ShiftListComponent,
    ShiftDialog,
    ShiftEditComponent
  ],
  providers: [ 
    ShiftService,
    {provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  exports: [
    ShiftListComponent,
    ShiftEditComponent
  ]
})
export class ShiftsModule {
  constructor(private dateAdapter:DateAdapter<Date>) {
    dateAdapter.setLocale('es-ES'); // DD/MM/YYYY
  }
 }
