export class App {
  constructor(
    public idApp: number,
    public name: string,
    public image: Text,
    public description: string,
    public icon: string,
    public index: number,
    public selected?: boolean
    ) { }
}
