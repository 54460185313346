import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule,
         MatInputModule,
         MatCardModule,
         MatSelectModule,
         MatDialogModule,
         MatIconModule,
         MatButtonModule,
         MatAutocompleteModule
        }                     from '@angular/material';

import { AccountsRoutingModule }   from './accounts-routing.module';
import { AccountListComponent }    from './account-list/account-list.component';
import { AccountEditComponent }         from './account-edit/account-edit.component';

import { AccountAppEditComponent } from './account-app-edit/account-app-edit.component';

import { AccountService}          from './account.service';

import { RolesModule}          from './../roles/roles.module';
import { OrganizationsModule}  from './../organizations/organizations.module';
import { LanguagesModule}  from './../languages/languages.module';
import { SharedModule }        from './../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
    AccountsRoutingModule,
    RolesModule,
    OrganizationsModule,
    LanguagesModule,
    SharedModule
  ],
  entryComponents: [
    // DialogComponent
  ],
  declarations: [
    AccountListComponent,
    AccountEditComponent,
    AccountAppEditComponent
    // ,
    // DialogComponent
  ],
  providers: [
    AccountService
  ],
  exports: [
    AccountListComponent,
    AccountAppEditComponent
  ]
})
export class AccountsModule { }
