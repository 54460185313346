import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, take, tap} from 'rxjs/operators';

// import { Account } from '../interfaces/account.interface';
import { AuthService } from '../auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class ErrorHttpConfigInterceptor implements HttpInterceptor {
  public constructor(private auth: AuthService) {}

  tokenRefreshed$ = new BehaviorSubject<boolean>(false);
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 0:
          case 504:
            break;
          case 401:
            // debugger;
            if(this.auth.isRefreshing && req.url.includes('refresh-token' )) {
                this.handleUnauthorized();
                return EMPTY;
            }
            this.auth.isRefreshing = true;
            // console.log('****INICIANDO REFRESH TOKEN****', req.url);
            return this.auth.refreshToken().pipe(
                finalize(() => this.auth.isRefreshing = false),
                concatMap((res: any) => {
                    this.auth.setToken(res.token);
                    // console.log('****TOKEN ACTUALIZADO****');
                    const requestClone = this.auth.addTokenHeader(req);
                    return next.handle(requestClone)
                }),
                catchError((err) => {
                    // console.log('*******ERROR EN EL REFRESH TOKEN********', err);
                    if (err.status === 401 && req.url.includes('refresh-token')) {
                        // Unauthorized
                        this.handleUnauthorized();
                    } else if(err.status === 401 && err.error && err.error.message && err.error.message.includes('This hardware is not allowed access')) {
                      this.handleUnauthorized('This hardware is not allowed access');
                    }
                    return EMPTY;
                })
            )
            break;
          case 451:
            // Unavailable For Legal Reasons
            // this.handleUnavailableForLegalReasons();
            break;
          default:
            break;
        }
        return throwError(error);
      })
    );
  }

  /**
   * Handle 401 code: Unauthorized
   * 
   * We just logout the user
   */
  private handleUnauthorized(text?: string): void {
    this.auth.logout(text);
  }

  /**
   * Handle 451 code: Unavailable For Legal Reasons.
   * 
   * This error code comes when the user has not accepted the current EULA.
   * We redirect users to the Manager so that they can accept the document.
   */
//   private handleUnavailableForLegalReasons(): void {
//     this.auth.getAccount().subscribe((account: Account): void => {
//       const username = account.username;
//       window.location.href = `${environment.managerUrl}/#/login/user?id=${username}`;
//     }, () => {
//       window.location.href = environment.managerUrl;
//     });
//   }
}
