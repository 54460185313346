import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { FormsModule }        from '@angular/forms';
import { MatFormFieldModule,
         MatInputModule,
         MatCardModule,
         MatSelectModule,
         MatRadioModule,
         MatTabsModule,
         MatDialogModule,
         MatIconModule,
         MatButtonModule
        }                     from '@angular/material';

import { AppsRoutingModule }  from './apps-routing.module';
import { AppListComponent }   from './app-list/app-list.component';
import { AppEditComponent }   from './app-edit/app-edit.component';

import { AppService }         from './app.service';
// import { DialogComponent }    from './app-edit/app-edit.component';

import { AccountsModule }     from './../accounts/accounts.module';

import { SharedModule }       from './../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule,
    MatRadioModule,
    MatDialogModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    AppsRoutingModule,
    AccountsModule,
    SharedModule
  ],
  entryComponents: [
    // DialogComponent
  ],
  declarations: [
    AppListComponent,
    AppEditComponent
    //,
    // DialogComponent
  ],
  providers: [ 
    AppService
  ]
})
export class AppsModule { }
