import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Inject,
  Output,
  Input
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';

import { Shift } from '../shift';
import { ShiftService } from '../shift.service';

import 'rxjs/add/operator/catch';
import { AlertService } from '../../../alert.service';
import { MainService } from '../../main.service';
import { AuthService } from '../../../auth.service';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import  { CardHeaderEditComponent } from '../../shared/cardHeader-edit/cardHeader-edit.component';

import * as _ from 'lodash';
import * as moment from 'moment';
import { ShiftsUtils } from '../../../utils/shifts';

@Component({
  selector: 'shift-edit',
  templateUrl: './shift-edit.component.html',
  styleUrls: ['./shift-edit.component.css']
})
export class ShiftEditComponent implements OnInit {

  @ViewChild(CardHeaderEditComponent) header;
  @Output() editMode: boolean = false;
  errorMessage: string;
  shift: Shift;
  module: string = 'Shifts';
  cardHeaderState: string = 'onlyEdit';
  initialCardHeaderState = 'onlyEdit';
  state: any;
  usedInCalendar: boolean = false;
  canBeSetStartDayBefore: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private shiftService: ShiftService,
    private _location: Location,
    private alertService: AlertService,
    private authService: AuthService,
    private mainService: MainService,
    public dialog: MatDialog,
    private shiftsUtils: ShiftsUtils
  ) { }

  async ngOnInit() {
    
    this.route.data
    .subscribe(async (data: { shift: Shift }) => {
      if (data.shift) {
          this.shift = data.shift;
          this.canBeSetStartDayBefore = await this.shiftsUtils.checkShiftCanBeSetStartDayBefore(this.shift);
          this.shiftService.shiftUsedInCalendar(this.shift.idShift).subscribe((res)=>{
            if (res.shiftUsedInCalendar) {
              this.usedInCalendar = true;
            }
          });
        } else {
          this.shift = new Shift(null, null, null, null, '#000000', false, null);
          this.editMode = true;
          this.cardHeaderState = 'new';
          this.initialCardHeaderState = 'onlyEdit';
          this.state = await this.mainService.getState();
          this.shift.idOrganization = this.state.organization.idOrganization;
          this.mainService.setState(null);
        }
      });
  }

  onheaderActionEmitter(message: any): void {
    switch (message.text) {
      case 'edit':
        this.editMode = !this.editMode;
        break;
      case 'save':
        this.onSave();
        break;
      case 'cancel':
        this.editMode = !this.editMode;
        break;
      case 'remove':
        this.onDelete();
        break;
      case 'back':
        this.onCancel();
        break;
    }
  }

  onCancel(): void {
    this._location.back();
  }

  onSave(): void {
    const keys = Object.keys(this.shift);
    const errors = keys.some(key => {
      return (key !== 'idShift' && key !== 'startDayBefore') ? this.fieldRequired(key) : false;
    });
    if (!errors) {
        if (this.usedInCalendar) {
          const dialogRef = this.dialog.open(ShiftDialog, {
            data: {
              body: "Shift " + this.shift.name + " is being used in at least one calendar. Changes might cause shift to overlap. Do you want to continue?"
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.saveShift();
            } else {
              this.header.cardHeaderState = 'edit';
              this.editMode = !this.editMode;
            }
          });
        } else {
          this.saveShift();
          this.editMode = !this.editMode;
        }
    }
  }

  saveShift() {
    this.shiftService.saveShift(this.shift)
    .subscribe(
      response => { this._location.back() },
      error => this.errorMessage = <any>error);
  }

  onDelete(): void {
    if (this.usedInCalendar) {
      const dialogRef = this.dialog.open(ShiftDialog, {
        data: {
          body: "Shift " + this.shift.name + " is being used in at least one calendar. Deleting it will get the shift removed from all the calendars. Do you want to continue?"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.removeShift();
        }
      });
    } else {
      this.removeShift();
    }
  }

  removeShift() {
    this.shiftService.removeShift(this.shift.idShift)
    .subscribe(
      response => { this._location.back() },
      error => this.errorMessage = <any>error)
    this.editMode = !this.editMode;
  }

  private fieldRequired(key): boolean {
    if (!this.shift[key]) {
      this.alertService.emitErrorMessage({ text: key.charAt(0).toUpperCase() + key.slice(1) + ' is required. Please fill out the field and resubmit the form', type: 'danger' });
      return true;
    }
    return false;
  }
}

@Component({
  selector: '[dialogtest]',
  template: `<h2 mat-dialog-title>Shift edit</h2>
            <mat-dialog-content>
              {{data.body}}
            </mat-dialog-content>
            <mat-dialog-actions>
              <button mat-button [mat-dialog-close]="false">No</button>
              <button mat-button [mat-dialog-close]="true">Yes</button>
            </mat-dialog-actions>`
})
export class ShiftDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any
  ) {}
}
