import { Expression } from "../main/notificationRules/expression";
import mathjs from 'mathjs';
import * as _ from 'lodash';
export class ExpressionsUtils {
    idBase = "EXP_";
    initialExpression: Expression = new Expression(`${this.idBase}0`,{type: null, id: null, absolute: false},null,{type: null, value: null});
    validValueTypes: string[] = ["BOOLEAN", "STRING", "NUMBER", "NULL"];
    validValueTypesGL: string[] = ["NUMBER"];
    operationTypes: string[] = ["==","!=","<",">"];
    logicalOperations: string[] = ["(",")","and","or"];

    public onAddExpression(expressions: Expression[]){
        let expression = JSON.parse(JSON.stringify(this.initialExpression));
        expression.id = expressions.length > 0 ? `${this.idBase}${this.getNumberFromId(expressions[expressions.length - 1].id)+1}` : expression.id;
        return expression;
    }

    private getNumberFromId(id: string){
        try {
            return +id.slice(this.idBase.length);
        } catch (error) {
            return -1;
        }
    }

    public onRemoveExpression(expressions: Expression[], expression: Expression, i: number): Expression[] {
        if (expressions.length > 0) {
            expressions.splice(i,1);
            expressions.forEach((exp, j)=>{
                exp.id = `${this.idBase}${j}`;
            });
        }
        return expressions;
    }


    public onBBJSignalChange(event, expression: Expression): Expression {
        if (event.value.idBBJVariable != null) {
            expression.variable.type = 'BBJVariable';
            expression.variable.id = event.value.idBBJVariable;
          } else {
            expression.variable.type = null;
            expression.variable.id = null;
        }
        return expression;
    }

    public async onOperationChange(event, expression: Expression) {
        expression.operation = event.value;
        const valid = await this.getValidValueTypesForExpression(expression);
        if (!valid.includes(expression.value.type)) {
          expression.value.type = null;
          expression =  await this.onTypeChange(expression);
        }
        return expression;
    }

    private getValidValueTypesForExpression(expression: Expression) {
        if (!expression || !expression.operation) {
          return this.validValueTypes;
        }
        switch (expression.operation.toLowerCase()) {
          case "==":
            return this.validValueTypes;
          case "!=":
              return this.validValueTypes;
          case "<":
              return this.validValueTypesGL;
          case ">":
              return this.validValueTypesGL;
          default:
            return [];
        }
    }

    public onTypeChange(expression: Expression) {
        if (expression.value.type==null) {
          expression.value.value = null;
        } else if (expression.value.type=="BOOLEAN"){
          expression.value.value = true;
        } else if (expression.value.type=="STRING") {
          expression.value.value = "";
        } else if (expression.value.type != "NUMBER") {
          expression.variable.absolute = false;
        } else {
          expression.value.value = null;
        }
        return expression;
    }

    public async validateExpressions(expressions: any) {
        let validateAllResult;
        for (let i = 0; i < expressions.length; i++) {
            const expression = expressions[i];
            let validExpression = await this.validateExpression(expression);
            if (!validExpression.result) {
                return validExpression;
            } else {
                validateAllResult = validExpression;
            }
        }
        return validateAllResult;
    }

    public async validateExpressionThresholds(thresholds: any) {
      for (let i = 0; i < thresholds.length; i++) {
        const bbj = thresholds[i];
        if ((!bbj.duration || !bbj.expression.operation || !bbj.expression.value.value || !bbj.expression.value.type) && (bbj.duration || bbj.expression.operation || bbj.expression.value.value || bbj.expression.value.type)) {
          return {result: false, alert: {text: `Missing data for ${bbj.variable.prettyName} signal threshold configuration`, type: 'danger'}};
        }
      }
      return {result: true, alert: null};
    }

    public validateOperation(operation){
        if (operation.length < 1) {
            return {result: false, alert: {text: "Operation can not be empty. Please fill out the field and resubmit the form", type: 'danger'}};
        } else {
          const operationStr = this.getOperationStr(operation);
          const logicOperationStr = this.getLogicOperationStr(operation);
          try {
            mathjs.parse(operationStr);
            eval(logicOperationStr);
            return {result: true, alert: null};
          } catch (error) {
            return {result: false, alert: {text: "Invalid operation syntax. Please fill out the field and resubmit the form", type: 'danger'}};
          }
        }
    }

    private getOperationStr(operation){
        return operation.join(' ');
    }

    private getLogicOperationStr(operation){
        return operation
            .map(elem => elem.includes('EXP_') ? 'true' : elem)
            .map(elem => elem == 'or' ? '||' : elem)
            .map(elem => elem == 'and' ? '&&' : elem)
            .join(' ');
    }

    public castValue(value, valueType){
        switch (valueType) {
            case "BOOLEAN":
            return (value == true);
            case "STRING":
            return _.toString(value);
            case "NUMBER":
            return _.toNumber(value);
            case "NULL":
            return null;
            default:
            return null;
        }
    }
  
    private validateExpression(expression: Expression){
        if (!expression.variable.id || expression.variable.type != "BBJVariable"){
            return {result: false, alert: {text: `Invalid signal type for expression ${expression.id}. Please fill out the field and resubmit the form`, type: 'danger'}};
        } else if (!this.operationTypes.includes(expression.operation)) {
            return {result: false, alert: {text: `Invalid operation for expression ${expression.id}. Please fill out the field and resubmit the form`, type: 'danger'}};
        } else if (!this.validValueTypes.includes(expression.value.type)) {
            return {result: false, alert: {text: `Invalid value type for expression ${expression.id}. Please fill out the field and resubmit the form`, type: 'danger'}};
        } else if(!this.validValue(expression.value.type, expression.value.value)) {
            return {result: false, alert: {text: `Invalid value for expression ${expression.id}. Please fill out the field and resubmit the form`, type: 'danger'}};
        } else {
            return {result: true, alert: null};
        }
    }

    private validValue(valueType, value, validValueTypes = this.validValueTypes): Boolean{
        if (!validValueTypes.includes(valueType)){
            return false;
        } else {
            switch (valueType) {
            case "BOOLEAN":
                return [true,false].includes(value);
            case "STRING":
                return (_.isString(value));
            case "NUMBER":
                return _.isNumber(value);
            case "NULL":
                value = null;
                return true;
            default:
                return false;
            }
        }
    }

  public expressionToString(bbjVariables, expression: Expression) {
    if (expression.variable.id && expression && expression.value && expression.value.type) {
      switch (expression.value.type) {
        case 'NULL':
          return `${this.getBBJVariableName(bbjVariables, expression.variable.id)} ${expression.operation || ''} NULL`;

        case 'BOOLEAN':
          return `${this.getBBJVariableName(bbjVariables, expression.variable.id)} ${expression.operation || ''} ${(expression.value.value) ? 'TRUE': 'FALSE'}`;

        case 'STRING':
          return `${this.getBBJVariableName(bbjVariables, expression.variable.id)} ${expression.operation || ''} "${expression.value.value || ''}"`;

        case 'NUMBER':
          return `${this.getBBJVariableName(bbjVariables, expression.variable.id)} ${expression.operation || ''} ${(expression.value.value != null) ? expression.value.value.toString() : 'NaN'}`;

        default:
          return `${this.getBBJVariableName(bbjVariables, expression.variable.id)} ${expression.operation || ''}`;
      }
    } else {
      return "";
    }
  }

  private getBBJVariableName(bbjVariables, id){
    let bbj = bbjVariables.find((bbj)=>{
      return bbj.idBBJVariable == id;
    });
    return (bbj != null) ? bbj.prettyName : "";
  }
}