export class QueryState {
  queries: {
    id: {
      url: string,
      component: string
    },
    pageSize: number,
    pageIndex: number,
    sort: string,
    direction: string, // ASC or DESC
    filter: {}  
  }[] = [];
}