import 'rxjs/add/operator/switchMap';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { Organization }          from './../organization';
import { OrganizationService }   from './../organization.service';

@Component({
  selector: 'organization-detail',
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.css']
})
export class OrganizationDetailComponent implements OnInit {

  //errorMessage: string;
  organization: Organization;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private organizationService: OrganizationService
  ) {}

  ngOnInit() {
    this.route.data
      .subscribe((data: { organization: Organization }) => {
        this.organization= data.organization;
      });
  }
}
