export class Shift {
  constructor(
    public idShift: number,
    public name: string,
    public start: string,
    public end: string,
    public color: string,
    public startDayBefore: boolean,
    public idOrganization: number,
  ) { }
}
