import { Injectable }             from '@angular/core';
import { Router, Resolve, 
         RouterStateSnapshot,
         ActivatedRouteSnapshot } from '@angular/router';
import { Observable }             from 'rxjs/Observable';

import { Shift }          from '../shift';
import { ShiftService }   from '../shift.service';


@Injectable()
export class ShiftListResolver implements Resolve<Shift[]> {
  constructor(private shiftService: ShiftService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Shift[]> {
    //return this.shiftService.getShifts()
    return this.shiftService.getShifts(10, 0, 'weight', 'asc', '')
  }
}
