import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { View }      from './view';
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class ViewService {

  public constructor(private http: HttpClient) { }

  /**
   * Retrieves all views
   */
  public getViews(limit: number, offset: number, sort: string, direction: string, filter: string): Observable<View[]> { 
    let queryParams = new HttpParams();
    if(limit !== null) queryParams = queryParams.set('limit', limit.toString());
    if(offset !== null) queryParams = queryParams.set('offset', offset.toString());
    if(sort !== null) queryParams = queryParams.set('sort', sort.toString());
    if(direction !== null) queryParams = queryParams.set('direction', direction.toString());
    if(filter !== null) queryParams = queryParams.set('filter', JSON.stringify(filter));

    return this.http.get<View[]>(environment.api_url + '/views', { params: queryParams });
  };
  /**
   * Retrieves view details by a given id
   *
   * @param idView
   */
  public getView(idView: string): Observable<View> { 
    return this.http.get<View>(environment.api_url + '/view/' + idView);
  };

  /**
   * Retrieves organization views
   * 
   * @param idOrganization
   */
  public getOrganizationViews(idOrganization: number): Observable<View[]> {  
    return this.http.get<View[]>(environment.api_url + '/views/organization/' + idOrganization);
    };

  /**
   * Save view
   *
   * @param view
   */
  public saveView(view: View): Observable<any> {
    if (view.idView) {
      return this.updateView(view);
    } else {
      return this.newView(view);
    }
  };

  /**
   * Create new view
   *
   * @param view
   */
  public newView(view: View): Observable<any> { 
    return this.http.post(environment.api_url + '/view', view);
  };

  /**
   * Update view
   *
   * @param view
   */
  public updateView(view: View): Observable<any> { 
    return this.http.put(environment.api_url + '/view', view);
  };

  /**
   * Delete an view by a given id
   *
   * @param idView
   */
  public removeView (idView: number): Observable<any> { 
    return this.http.delete(environment.api_url + '/view/' + idView);
  }

  public getEntityViews (idEntity: string): Observable<View[]> { 
    return this.http.get<View[]>(environment.api_url + '/views/entity/' + idEntity);
  };

}
