import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShiftListComponent }   from './shift-list/shift-list.component';
import { ShiftListResolver}     from './shift-list/shift-list-resolver.service';
import { ShiftEditComponent }   from './shift-edit/shift-edit.component';
import { ShiftEditResolver }    from './shift-edit/shift-edit-resolver.service';

const shiftsRoutes: Routes = [
  { path: '',
    children: [
      {path: 'new', component: ShiftEditComponent },
      {path: 'edit/:id', component: ShiftEditComponent,
        resolve: {
          shift: ShiftEditResolver,
        }
      },
      {path: '', component: ShiftListComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(shiftsRoutes)],
  exports: [RouterModule],
  providers: [
    ShiftListResolver,
    ShiftEditResolver
  ]
})
export class ShiftsRoutingModule { }
