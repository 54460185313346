import { Component,
         OnInit,
         Output }                                   from '@angular/core';
import { Router, ActivatedRoute, Params }           from '@angular/router';
import { Location }                                 from '@angular/common';
import { DomSanitizer }                             from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MatSelectChange, MAT_DIALOG_DATA } from '@angular/material';

import { AlertService }          from './../../../alert.service';

import { Organization }          from './../organization';
import { Account }          from './../../accounts/account';
import { OrganizationService }   from './../organization.service';

import { FormControl, ReactiveFormsModule }         from '@angular/forms';

//import { RoleService } from '../../roles/role.service';
import { Role }        from '../../roles/role';

import 'rxjs/add/operator/switchMap';
import { App } from '../../apps/app';
import { AuthService } from '../../../auth.service';
import { TabStore } from '../../shared/store/tab.store';
// import { View } from '../../views/view';
// import { ViewService } from '../../views/views.service';
import { AppService } from '../../apps/app.service';
import { ViewService } from '../../views/views.service';
import { View } from '../../views/view';
declare var _:any;
@Component({
  selector: 'organization-edit',
  templateUrl: './organization-edit.component.html',
  styleUrls: ['./organization-edit.component.css']
})
export class OrganizationEditComponent implements OnInit {

  errorMessage: string;
  organization: Organization;
  organizations: Organization[];
  roles: Role[];
  initialRoles: Role[];
  apps: App[] = [];
  organizationApps: App[];
  showApps: boolean = false;

  organizationViews: any[];
  viewControl: FormControl = new FormControl();

  @Output() editMode: boolean = false;
  module: string = 'Organization';
  cardHeaderState: string = 'edit';
  initialCardHeaderState: string;
  currentTabIndex: number;
  componentName = this.module;
  url = this._location.path(false).split(/[?#]/)[0];
  tabStateId = {
    url: this.url,
    component: this.componentName
  };

  parent: boolean = false;
  isAdmin: boolean = false;
  isEndUser: boolean = false;
  showCalendar: boolean = false;
  canCreate: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private organizationService: OrganizationService,
    private _location: Location,
    private authService: AuthService,
    public dialog: MatDialog,
    private tabStore: TabStore,
    private appsService: AppService,
    private viewService: ViewService,
    ) {}

  ngOnInit() {
    this.authService.getAccount().subscribe((account) => {
      this.route.data
        .subscribe((data: { organization: Organization, roles: Role[], organizations: Organization[] }) => {
          if(data.organization) {
            if (account.idOrganization == data.organization.idOrganization) { this.parent = true }
            let hierarchy = data.organization.hierarchy;
            let newHierarchy;
            if (data.organization.idOrganization == 1) {
              newHierarchy = hierarchy;
            } else {
              let orgStr = "-" + data.organization.idOrganization.toString() + "-/";
              let indexof = hierarchy.lastIndexOf(orgStr);
              newHierarchy = hierarchy.slice(0, indexof);
            }
            // updated the organization hierarchy to the parent organization hierarchy
            data.organization.hierarchy = newHierarchy;
            this.organization = data.organization;
      
            // Organizations parsed removing the actual organization editing
            this.organizations = data.organizations['rows'].filter((obj) => {
              return obj.idOrganization !== data.organization.idOrganization;
            });
          } else {
            if ( account.idRole == 3 ) { this.router.navigate(['/']); }
            this.organization = new Organization(null, null, '','',null,'', null);
            this.editMode = true;
            this.cardHeaderState = 'new';
            this.initialCardHeaderState = 'onlyEdit';
            this.organizations = data.organizations['rows'];
          }
          this.checkAccountPermissions(account);
          if (this.showCalendar) {
            this.initSelectedTab();
          }
          if (this.showApps) {
            this.getAppsAndViews();
            this.getOrgApps(this.organization.idOrganization ? this.organization.idOrganization.toString() : null);
            this.getOrgViews(this.organization.idOrganization);
          }
          this.roles = data.roles['rows'];
          this.initialRoles = this.roles;
        });
    });
  }

  private checkAccountPermissions(account: Account): void {
    this.showCalendar = this.organization.idOrganization == account.idOrganization || this.organization.hierarchy.includes('/-'+account.idOrganization+'-/');
    switch (account.idRole) {
      case 1:
        this.isAdmin = true;
        this.showApps = true;
        break;
      case 2:
        break;
      case 3:
        this.isEndUser = true;
        this.canCreate = false;
        this.cardHeaderState = 'disabled';
        break;
      default:
        break;
    }
  }

  private getAppsAndViews(): void {
    this.appsService.getApps(
      null,
      null,
      'name',
      'ASC',
      null
    ).subscribe((res: any) =>{
      this.apps = res['rows'];
      if (this.organization.idRole == null) {
        // select all views only on organization create
        this.selectAllViews();
      }
    });
  }

  private getOrgApps(idOrganization: string): void {
    if (idOrganization == null) return;
    this.appsService.getOrganizationApps(idOrganization.toString()).subscribe((apps: App[])=>{
      this.organizationApps = apps;
      // this.loadSelectedApps();
    });
  }

  private selectAllViews(): void {
    this.organizationViews = []
    this.apps.forEach((app) => {
      Array.prototype.push.apply(this.organizationViews, app["views"]);
    });
    this.organizationViews;
    this.viewControl.setValue(this.organizationViews);
  }

  private getOrgViews(idOrganization: number): void {
    if (idOrganization == null) return;
    this.viewService.getOrganizationViews(idOrganization).subscribe((views: View[])=>{
      this.organizationViews = views;
      this.viewControl.setValue(this.organizationViews);
    });
  }

  // loadSelectedApps() {
  //   for (let app of this.apps) {
  //     app.selected = false;
  //     for (let oApp of this.organizationApps) {
  //       if (app.idApp === oApp.idApp) {
  //         app.selected = true;
  //         break;
  //       }
  //     }
  //   }
  // }

  private initSelectedTab(): void {
    this.currentTabIndex = this.tabStore.getTabIndex(this.tabStateId);
    if (!this.currentTabIndex) {
      this.currentTabIndex = 0;
      this.tabStore.addTab(this.tabStateId, this.currentTabIndex);
    }
  }

  public onTabChange(event): void {
    this.tabStore.setTabIndex(this.tabStateId, event.index);
  }

  onheaderActionEmitter(message:any):void {
    switch (message.text) {
      case 'edit':
        this.editMode = !this.editMode;
        // this.onNew();
        break;
      case 'save':
        this.onSave(this.organization);
        // this.onSelect(message.data);
        this.editMode = !this.editMode;
        break;
      case 'cancel':
        // this.onEdit(message.data);
        this.editMode = !this.editMode;
        break;
      case 'remove':
        this.onDelete();
        // this.onRemove(message.data);
        break;
      case 'back':
        this.onCancel();
        break;
    }
  }

  // appSelectionChange(event: any, app: any) {
  //   if (event.checked) {
  //     for (let view of app.views) {
  //       let v = this.organizationViews.find(x => x.idView === view.idView);
  //       if (!v) {
  //         this.organizationViews.push(view);
  //       }
  //     }
  //   } else {
  //     for (let view of app.views) {
  //       let v = this.organizationViews.find(x => x.idView === view.idView);
  //       if (v) {
  //         this.organizationViews = this.organizationViews.filter(v => v.idView !== view.idView)
  //       }
  //     }
  //   }
  //   this.organizationApps = this.apps.filter(app => app.selected === true);
  //   this.viewControl.setValue(this.organizationViews);
  // }

  // viewSelectionChange(event) {
  //   if (event.value.length > this.organizationViews.length) {
  //     let viewIDs = this.organizationViews.map(x => x.idView)
  //     let view = event.value.find(x => !viewIDs.includes(x.idView));
  //     let parentApp = this.apps.find(x => x['views'].includes(view))
  //     parentApp.selected = true;
  //   } else {
  //     let selectedApps = this.apps.filter(app => app.selected === true);
  //     for (let app of selectedApps) {
  //       let active = false;
  //       for (let view of app['views']) {
  //         let viewInOrganization = event.value.find(x => x.idView === view.idView);
  //         if (viewInOrganization) {
  //           active = true;
  //           break;
  //         }
  //       }
  //       app.selected = active;
  //     }
  //   }
  //   this.organizationApps = this.apps.filter(app => app.selected === true);
  //   this.organizationViews = event.value;
  // }

  onChangeOrg(event: any, organization: any){
    //check if is bit to activate the new input
    if(event.isUserInput){
      this.roles = _.filter(this.initialRoles, function(o) {
        return o.weight >= organization.role.weight;
      });
      if (this.cardHeaderState == 'new' && this.isAdmin) {
        this.getOrgApps(organization.idOrganization.toString());
      }
    }
  }

  compareApps(c1: App, c2: App): boolean {
    return c1 && c2 ? c1.idApp === c2.idApp : c1 === c2;
  }

  compareViews(c1: View, c2: View): boolean {
    return c1 && c2 ? c1.idView === c2.idView : c1 === c2;
  }

  onFileChanged(event){
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      if(file.size < 50000) {
      let fr = new FileReader();
        fr.onload = (event:any)=>{
          let base64 = event.target.result
          this.organization.image = base64;
        }
        fr.readAsDataURL(file)
      } else {
        this.alertService.emitErrorMessage({text: 'File too big', type: 'error'});
      }
    }
  }

  onCancel(): void {
    this._location.back();
  }

  onSave(organization: Organization): void {
    //Fix to attach selected apps to the organization apps from the form

    if (this.organizationApps) {
      this.organization["apps"]= this.organizationApps;
    }
    else
    {
      // Case of new /post: so the accountApps resolver is not defined
      // In that case we force to be a empty Array
      this.organization["apps"]= [];
    }

    if (this.organizationViews) {
      this.organization["views"]= this.organizationViews;
    }
    else
    {
      // Case of new /post: so the accountViews resolver is not defined
      // In that case we force to be a empty Array
      this.organization["views"]= [];
    }

    if (!this.organization.name) {
      this.alertService.emitErrorMessage({text: 'Name is required. Please fill out the field and resubmit the form', type: 'danger'});
      this.editMode = !this.editMode;
    }
    else if (!this.organization.hierarchy)
    {
      this.alertService.emitErrorMessage({text: 'Parent organization is required. Please fill out the field and resubmit the form', type: 'danger'});
      this.editMode = !this.editMode;
    }
    else if (!this.organization.idRole && this.isAdmin)
    {
      this.alertService.emitErrorMessage({text: 'Role is required. Please fill out the field and resubmit the form', type: 'danger'});
      this.editMode = !this.editMode;
    }
    else
    {
      this.organizationService.saveOrganization(this.organization)
                              .subscribe(
                                response => {
                                  this._location.back();
                                },
                                error =>  {
                                  this.alertService.emitErrorMessage({text: error.error, type: 'danger'});
                                  this.editMode = !this.editMode;
                                  this.errorMessage = <any>error
                                });
    }

  }

  onDelete(): void {
    this.organizationService.removeOrganization(this.organization.idOrganization)
                            .subscribe(
                              response => {
                                this._location.back();
                              },
                              error =>  {
                                this.alertService.emitErrorMessage({text: error.error, type: 'danger'});
                                // this.editMode = !this.editMode;
                                this.errorMessage = <any>error
                              });
  }

}
