import { Component, OnInit, Inject }                  from '@angular/core';
import { Router, ActivatedRoute }             from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Location }                           from '@angular/common';

import { Entity }             from './../entity';
import { EntityService}       from './../entity.service';

import { EntityModel }        from './../../entityModels/entityModel';
import { DataloggerHardware } from './../../dataloggerHardwares/dataloggerHardware';
import { DatasourceModel }    from './../../datasourceModels/datasourceModel';
import { DatasourceModelService } from './../../datasourceModels/datasourceModel.service';
import { DataloggerSoftwareService } from './../../dataloggerSoftwares/dataloggerSoftware.service';
import { MainService}         from './../../main.service';
import { DataloggerSoftware } from './../../dataloggerSoftwares/dataloggerSoftware';
import { Datasource }         from './../../datasources/datasource';
import { DatasourceService }  from './../../datasources/datasource.service';
import { BBJVariableService } from './../../bbjVariables/bbjVariable.service';
import { MapVariableService } from './../../mapVariables/mapVariable.service';
import { SettingService } from './../../settings/setting.service';

import {Observable} from 'rxjs/Observable';
import {startWith}  from 'rxjs/operators/startWith';
import {map}        from 'rxjs/operators/map';
import { AlertService } from '../../../alert.service';
import { AuthService } from '../../../auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';

import * as L from 'leaflet';
import 'leaflet-control-geocoder';

declare var _:any;

@Component({
  selector: 'entity-new',
  templateUrl: './entity-new.component.html',
  styleUrls: ['./entity-new.component.css']
})
export class EntityNewComponent implements OnInit {


  errorMessage: string;
  entity: Entity;
  entityModels: EntityModel[] = [];
  dataloggerHardwares: DataloggerHardware[] = [];
  freeDataloggerHardwares: DataloggerHardware[] = [];
  dataloggerSoftwares: DataloggerSoftware[] = [];
  datasourceModels: DatasourceModel[] = [];

  datasource: Datasource;
  sourceFieldsName: string = '';
  fields: any[] = [];
  mapVariableFields: any[] = [];
  isAdmin: boolean = false;
  freeVixionEdge: boolean = false;
  vixionEdgeNext: boolean = false;
  showMapVariables: boolean = false;
  auxDM: any;
  today: Date = new Date();
  myControl_all: FormControl = new FormControl();
  myControl2: FormControl = new FormControl();
  myControl3: FormControl = new FormControl();
  filteredAllDataloggerHardwares: Observable<DataloggerHardware[]>;
  filteredDatasourceModels: Observable<DatasourceModel[]>;
  filteredEntityModels: Observable<EntityModel[]>;
  isLinear = false;
  zeroFormGroup: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  entityInfo: { location: { lat: string, lng: string }, hourlyPrice: { hourlyPrice: number}, oeeObjective: { oeeObjective: number}};
  locationString: string;
  saving: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
    private mainService: MainService,
    private entityService: EntityService,
    private datasourceService : DatasourceService,
    private datasourceModelService : DatasourceModelService,
    private dataloggerSoftwareService: DataloggerSoftwareService,
    private mapVariableService : MapVariableService,
    private bbjVariableService : BBJVariableService,
    private settingService : SettingService,
    private alertService: AlertService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.entityInfo = { location: { lat: null, lng: null }, hourlyPrice: { hourlyPrice: 70}, oeeObjective: { oeeObjective: 70}};
   }

  ngOnInit() {
    this.authService.getRole().then((role) => {
      if ( role == 1) { this.isAdmin = true }
      if ( role == 3) { this.router.navigate(['/']); }
    this.route.data
      .subscribe((data: { entityModels: EntityModel[], dataloggerHardwares: DataloggerHardware[], freeDataloggerHardwares: DataloggerHardware[], datasourceModels: DatasourceModel[], dataloggerSoftwares: DataloggerSoftware[]}) => {
        this.entityModels = data.entityModels['rows'];
        this.dataloggerHardwares = data.dataloggerHardwares['rows'];
        this.datasourceModels = data.datasourceModels['rows'];
        this.freeDataloggerHardwares = data.freeDataloggerHardwares;
        if (this.freeDataloggerHardwares.length > 0) {
            this.freeVixionEdge = true;
        }

        // this.dataloggerSoftwares = data.dataloggerSoftwares;

        this.filteredAllDataloggerHardwares= this.myControl_all.valueChanges
            .pipe(
              startWith(''),
              map(val => this.filter_all(val))
            );
      this.filteredDatasourceModels = this.myControl2.valueChanges
          .pipe(
            startWith(''),
            map(val => this.filter2(val))
          );

      this.filteredEntityModels = this.myControl3.valueChanges
          .pipe(
            startWith(''),
            map(val => this.filter3(val))
          );
      });

      if (this.isAdmin) {
        this.firstFormGroup = this._formBuilder.group({
          serialNumber: ['', Validators.required],
          name: ['', Validators.required],
          mongoDB: ['', Validators.required],
          dataPath: ['', Validators.required],
          //manufactureYear: ['', Validators.pattern('^[0-9]*$')],
          manufactureYear: [null, null],
          // licenseStart: [null, null],
          nameSpace: [null, null],
          hourlyPrice: [70, Validators.required],
          oeeObjective: [70, Validators.required]
          // ,
          // idEntityModel: ['', Validators.required]
        });
      }
      else {
        this.firstFormGroup = this._formBuilder.group({
          serialNumber: ['', Validators.required],
          name: ['', Validators.required],
          // mongoDB: ['', Validators.required],
          // dataPath: ['', Validators.required],
          //manufactureYear: ['', Validators.pattern('^[0-9]*$')],
          manufactureYear: [null, null],
          hourlyPrice: [70, Validators.required],
          oeeObjective: [70, Validators.required]
          // licenseStart: [null, null]
          // ,
          // idEntityModel: ['', Validators.required]
        });
      }

    this.secondFormGroup = this._formBuilder.group({
      // idDatasourceModel: ['', Validators.required],
      address: ['', Validators.required]
    });
    });

  }

  filter_all(val: any): DataloggerHardware[] {
    return this.dataloggerHardwares.filter(dataloggerHardware =>
      dataloggerHardware.serialNumber.toLowerCase().includes(val.toLowerCase()));
  }

  filter2(val: any): DatasourceModel[] {
    return this.datasourceModels.filter(datasourceModel =>
      datasourceModel.name.toLowerCase().includes(val.toLowerCase()));
  }

  filter3(val: any): EntityModel[] {
    return this.entityModels.filter(entityModel =>
      entityModel.name.toLowerCase().includes(val.toLowerCase()));
  }

  composeEntityModel(entityModel: EntityModel): string {
    return entityModel.name+ ' - ' + entityModel["organization"].name
  }

  onLocationClick() {
    let dialogRef = this.dialog.open(MapDialogComponent2,{
      data: { location: this.entityInfo ? this.entityInfo.location : undefined }
    });
    dialogRef.afterClosed().subscribe(res => {
      this.entityInfo.location = res? res : this.entityInfo.location;
      this.locationString = this.entityInfo.location ? this.entityInfo.location.lat + ', ' + this.entityInfo.location.lng : this.locationString;
    });
  }

  onChangeVixionEdge(): void {
      this.vixionEdgeNext = true;
  }

  onChange(event: any, selectedDM: any): void {
    this.showMapVariables = false;
    if (event.isUserInput) {
       //do something

    // event.value;
    // let selectedDM = _.filter(this.datasourceModels, (o) => {
    //   return (o.idDatasourceModel == dsM.idDatasourceModel);
    // });
    //get DatasourceModel to redraw sourceFields
    this.datasourceModelService.getDatasourceModel(selectedDM.idDatasourceModel.toString())
        .subscribe(datasourceModel => {
            let sourceFields = datasourceModel.sourceFields;
            let sourceKey = datasourceModel.sourceKey;
            this.auxDM = sourceFields;
            // this.sourceFieldsName = Object.keys(datasourceModel.sourceFields).pop();
            //Transform a JSON object into Array to create input fields
            let source2Display = _.omit(sourceFields, ['id', 'address', 'alarmTextFiles']);
            if (!this.isAdmin) {
              // IF HEIDENHAIN DELETE OMIT USER AND PASSWORD
              if (sourceKey != 'DataSourceSinumerikConfig' && sourceKey != 'DataSourceOpcConfig' && sourceKey != 'DataSourceLogfileConfig')
              {
                  if (source2Display.hasOwnProperty("user")) {
                    source2Display = _.omit(source2Display, ['user']);
                  }
                  if (source2Display.hasOwnProperty("password")) {
                    source2Display = _.omit(source2Display, ['password']);
                  }
              }

              if (sourceKey == 'DataSourceSinumerikConfig') {
                  source2Display = _.omit(source2Display, [ 'subscribedMaximumItems','subscriptionMaximumPeriod']);
              }
            }

            this.mainService.jsonToArr(source2Display, null)
            .then(arr => {
              //add new fields
              this.fields = arr;
              // let arr2Display = _.omit(arr, ['id', 'address']);
              //generate controls for each field
              // this.fields = arr2Display;
              // this.canThirdForm = true;
              this.thirdFormGroup= this._formBuilder.group({});
              arr.map( field => this.thirdFormGroup.addControl(field.key, new FormControl('', [])) );
            });

          }, error =>  this.errorMessage = <any>error);


    this.mapVariableService.getMapVariablesBymodel(selectedDM.idDatasourceModel.toString())
        .subscribe(result => {

            let mapVariables = result['rows'];

            this.fourthFormGroup= this._formBuilder.group({});

            mapVariables.map( mapVariable => {
              if (mapVariable.show === true) {
                let key = mapVariable.value["id"];
                this.mapVariableFields.push({
                  key: key,
                  value: '',
                  label: mapVariable.masterVariable.prettyName
                });
                this.fourthFormGroup.addControl(key, new FormControl('', []));
              }
            })

            if (!_.isEmpty(this.mapVariableFields) ) {
                this.showMapVariables = true;
            }

          }, error =>  this.errorMessage = <any>error);

    }
  }

  //compose vixion edge title with serialNumber and instance
  composeName(dataloggerSoftware: DataloggerSoftware): string {
    return dataloggerSoftware["dataloggerHardware"].serialNumber + ' - ' + dataloggerSoftware.instance
  }

  onFinish(): void {
    this.checkEntityModel()
    .then( () => {
    this.checkDatasourceAddress()
    .then( () => {
    this.checkDatasourceModel()
    .then( () => {
    this.checkDataloggerSoftware()
    .then( () => {
    //get selected EntityModel
    let selectedEntityModel = this.entityModels.filter(entityModel => {
      return entityModel.name === this.myControl3.value
    });

    //get selected vixion edge
    let selectedDataloggerHardware;
    //showing all
    selectedDataloggerHardware = this.dataloggerHardwares.filter(dataloggerHardware => {
      return dataloggerHardware.serialNumber === this.myControl_all.value
    });

    //get selected DatasourceModel
    let selectedDatasourceModel = this.datasourceModels.filter(datasourceModel => {
      return datasourceModel.name === this.myControl2.value
    });

    let idDataloggerHardwareValue = selectedDataloggerHardware.pop().idDataloggerHardware;
    let selectedDatasourceModelObj = selectedDatasourceModel.pop()
    let idDatasourceModelValue = selectedDatasourceModelObj.idDatasourceModel;
    let idEntityModelValue = selectedEntityModel.pop().idEntityModel;

    let mongoDB = null;
    let dataPath = null;
    let licenseStart = null;
    let nameSpace = null;
    let prebuildDS = new DataloggerSoftware(
      null,
      idDataloggerHardwareValue,
      "0.5.0",
      "-"
    );
    this.saving = true;
    this.dataloggerSoftwareService.saveDataloggerSoftware(prebuildDS)
    .subscribe(
      dataloggerSoftware => {

        if (this.isAdmin) {
            mongoDB = this.firstFormGroup.value.mongoDB;
            dataPath = this.firstFormGroup.value.dataPath;
            nameSpace = this.firstFormGroup.value.nameSpace;
        }
        // if (this.firstFormGroup.value.licenseStart && this.firstFormGroup.value.licenseStart != '' ) {
        //     licenseStart = this.firstFormGroup.value.licenseStart;
        // }
        if (!this.entityInfo.location.lat) {
          this.entityInfo.location = undefined;
        }
        this.entityInfo.hourlyPrice.hourlyPrice = this.firstFormGroup.value.hourlyPrice;
        this.entityInfo.oeeObjective.oeeObjective = this.firstFormGroup.value.oeeObjective;

        let entity = new Entity(
          null,
          idEntityModelValue,
          dataloggerSoftware.idDataloggerSoftware,
          this.firstFormGroup.value.serialNumber,
          this.firstFormGroup.value.name,
          this.firstFormGroup.value.manufactureYear,
          mongoDB,
          dataPath,
          licenseStart,
          this.entityInfo,
          nameSpace,
          null
        );

        let datasource = new Datasource(
          null,
          idDatasourceModelValue,
          dataloggerSoftware.idDataloggerSoftware,
          {},
          null,
          this.secondFormGroup.value.address,
        );

        // save the dinamic values generated from datasource model
        // datasource.value[this.sourceFieldsName] = this.thirdFormGroup.value;
        // ÑAPA to add id + address values
        let newJson= _.merge(this.auxDM, this.thirdFormGroup.value);
        if (newJson.hasOwnProperty('id')) {
            newJson.id = selectedDatasourceModelObj.name.replace(/\s/g,"_");;
        }
        if (newJson.hasOwnProperty('address')) {
            newJson.address = datasource.address;
        }

        datasource.value= newJson;

        //save entity
        this.entityService.saveEntity(entity)
            .subscribe(
              entity => {

                //save datasource
                this.datasourceService.saveDatasource(datasource)
                    .subscribe(
                      (datasource: Datasource) => {

                      let mapSettingBodyObject = {
                        idEntity: entity.idEntity,
                        idDatasourceModel: datasource.idDatasourceModel
                      };

                      this.settingService.generateMapSettings(mapSettingBodyObject)
                          .subscribe(
                            response => {
                              // this._location.back()
                            },
                            error =>  {
                              //Rollback
                              this.entityService.removeEntity(entity.idEntity)
                              .subscribe(response => this._location.back())
                            }
                          );

                        let generateBody = {
                          datasource: datasource,
                          variablesForm: this.fourthFormGroup.value
                        };

                        this.bbjVariableService.generateFromDatasource(generateBody)
                            .subscribe(
                              response => this._location.back(),
                              error =>  {
                                //Rollback
                                this.entityService.removeEntity(entity.idEntity)
                                .subscribe(response => this._location.back())
                              }
                            )
                      },
                      error =>  {
                        //Rollback
                        this.entityService.removeEntity(entity.idEntity)
                        .subscribe(response => this._location.back())
                      }
                    )
              },
              error =>  {
                this.errorMessage = error.statusText || <any>error;
                this.alertService.emitErrorMessage({text: this.errorMessage, type: 'danger'});
                this.saving = false;
              })
        },
        error =>  {
          this.errorMessage = error.statusText || <any>error;
          this.alertService.emitErrorMessage({text: this.errorMessage, type: 'danger'});
          this.saving = false;
        }
        );

        }

        )
        .catch((error) => {
          this.errorMessage = error.statusText || <any>error;
        this.alertService.emitErrorMessage({text: this.errorMessage, type: 'danger'});
        this.saving = false;
        })

      }

      )
      .catch((error) => {
        this.errorMessage = error.statusText || <any>error;
        this.alertService.emitErrorMessage({text: this.errorMessage, type: 'danger'});
        this.saving = false;
      })

      }

      )
      .catch((error) => {
        this.errorMessage = error.statusText || <any>error;
        this.alertService.emitErrorMessage({text: this.errorMessage, type: 'danger'});
        this.saving = false;
      })

      }

      )
      .catch((error) => {
        this.errorMessage = error.statusText || <any>error;
        this.alertService.emitErrorMessage({text: this.errorMessage, type: 'danger'});
        this.saving = false;
      })

  }

  checkEntityModel(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if(this.myControl3.value) {
        resolve();
      } else {
        this.alertService.emitErrorMessage({text: 'ERROR: Can not save Entity without selecting Entity Model. Please review step 1', type: 'danger'});

        reject();
      }
    });
  }

  checkDatasourceModel(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if(this.myControl2.value) {
        resolve();
      } else {
        this.alertService.emitErrorMessage({text: 'ERROR: Can not save Entity without selecting DatasourceModel. Please review step 2', type: 'danger'});

        reject();
      }
    });
  }

  checkDatasourceAddress(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if(this.secondFormGroup.value.address) {
        resolve();
      } else {
        this.alertService.emitErrorMessage({text: 'ERROR: Can not save Entity without Datasource address value. Please review step 3', type: 'danger'});

        reject();
      }
    });
  }

  checkDataloggerSoftware(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {

      if(this.myControl_all.value) {
        resolve();
      } else {
        this.alertService.emitErrorMessage({text: 'ERROR: Can not save Entity without selecting DataloggerSoftware. Please review step 1', type: 'danger'});

        reject();
      }
    });
  }

}

// //DIALOG COMPONENT
//
@Component({
  selector: '[mapdialog]',
  template: `
    <h2 mat-dialog-title>Select the location of the machine</h2>
    <mat-dialog-content><div id="map" style="width:1000px; height:500px;"></div></mat-dialog-content>
    <mat-dialog-actions align="end">
      <form [formGroup]="mapForm" style="width:100%;" (ngSubmit)="save(location)">
        <mat-form-field style="width:50%;">
          <input matInput type="number" id="latitude" name="latitude" placeholder="Latitude" formControlName="latitude"
            (input)="onInputChange()">
        </mat-form-field>
        <mat-form-field align="end" style="width:50%;">
          <input matInput type="number" id="longitude" name="longitude" placeholder="Longitude" formControlName="longitude"
            (input)="onInputChange()">
        </mat-form-field>
        <button mat-button type="button" (click)="cancel()">CANCEL</button>
        <button mat-button type="submit" style="justify-content: flex-end;">SAVE</button>
      </form>
    </mat-dialog-actions>`
})
export class MapDialogComponent2 implements OnInit {

  map: any;
  icon: any;
  marker: any;
  location: { lat: number, lng: number };

  mapForm: FormGroup;

  constructor(private dialogRef: MatDialogRef<MapDialogComponent2>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.location = this.data.location ? this.data.location : { lat: null, lng: null };
     }

  ngOnInit() {
    // Initialize the form
    this.mapForm = this.fb.group({
      latitude: [this.location.lat, [Validators.required]],
      longitude: [this.location.lng, [Validators.required]]
    });

    // Create the map
    this.map = L.map('map').setView([this.location.lat ? this.location.lat : 0, this.location.lng ? this.location.lng : 0], 2);
    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(this.map);
    var options = {
      collapsed: false,
      defaultMarkGeocode: false
    };
    /* L.Icon.Default.prototype.options = {
      iconUrl: 'assets/images/red_marker.png',
      iconSize: 30
    }; */
    this.icon = new L.Icon({
      iconUrl: 'assets/images/red_marker.png',
      iconSize: 30
    });
    if (this.location.lat && this.location.lng) {
      this.marker = L.marker([this.location.lat, this.location.lng], { icon: this.icon}).addTo(this.map);
      this.map.setView(this.location, 15);
    }
    // Geocoder controller for searching places in the map
    L.Control.geocoder(options).on('markgeocode', (e: any) => {
      this.map.fitBounds(e.geocode.bbox);
    })
      .addTo(this.map);

    // On click we add the marker to the map, get the position of it, and put the coordinates on the input
    this.map.on('click', (e: any) => {
      this.mapForm.get('latitude').setValue(e.latlng.lat);
      this.mapForm.get('longitude').setValue(e.latlng.lng);
      this.setMapPosition(e.latlng);
    });
  }

  onInputChange() {
    this.setMapPosition({ lat: this.mapForm.get('latitude').value, lng: this.mapForm.get('longitude').value });
  }

  /**
   * Function that sets the position of the marker in the map
   * @param location 
   */
  setMapPosition(location: any) {
    this.map.setView(location);
      this.location = { lat: location.lat, lng: location.lng };
      if (!this.marker) {
        this.marker = L.marker(location, { icon: this.icon}).addTo(this.map);
      }
      this.marker.setLatLng(location);
  }

  /**
   * Function that saves the selected location and closes the dialog window
   * @param location
   */
  save(location: any) {
    if (this.mapForm.valid) {
      location.lat = location.lat.toString();
      location.lng = location.lng.toString();
      this.dialogRef.close(location);
    }
  }

  /**
   * Function that closes the dialog window
   */
  cancel() {
      this.dialogRef.close();
  }

}
