import { Injectable }               from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Http,
  Headers,
  Response,
  RequestOptions }           from '@angular/http';

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { environment }  from './../environments/environment';
import { AlertService } from './alert.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, map, share, catchError } from 'rxjs/operators';
import { Account } from './main/accounts/account';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { RefreshToken } from './interfaces/refreshToken.interface';

// import * as Raven from 'raven-js';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isLoggedIn: boolean = null;
  isImpersonating: boolean;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  // private headers = new Headers({ 'Content-Type': 'application/json' });
  // private options = new RequestOptions({ headers: this.headers });
  headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  options = { headers: this.headers };

  // loggedAccount : any;
  private loggedAccount: Account;
  private $loggedAccount: Observable<any> = this.http.get(`${environment.api_url}/auth/account`).pipe(
    tap((account)=>this.setAccount(account)),
    share()
  );
  private _isRefreshing = false;
  private supportedLanguages = ['en', 'es', 'zh', 'de', 'it', 'fr'];
  private apiDown: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor(
    private http: HttpClient,
    public router: Router,
    private alertService: AlertService,
  ) {
    // maybe we should check if the token is valid
    this.isImpersonating = localStorage.getItem('token_impersonator') ? true : false;
  }

  get isRefreshing() {
    return this._isRefreshing;
  }

  set isRefreshing(value: boolean) { 
    this._isRefreshing = value;
  }

  public setToken(token: string): void {
    localStorage.setItem("token", token);
  }

  public static getToken(): string {
    return localStorage.getItem("token");
  }

  addTokenHeader(req: HttpRequest<any>) {
    const token = AuthService.getToken() || ''
  
    return req.clone({headers: req.headers.set('x-access-token', token)});
  }

  public refreshToken() { 
    return this.http.get<RefreshToken>(`${environment.api_url}/refresh-token`, {withCredentials: true});
  }

  private setAccount(account: any): void {
    if (account && account.idAccount != null) {
      this.loggedAccount = account;
      this.isLoggedIn = true;
    }
  }

  public isLoggednIn(): Observable<boolean> {
    if (this.isLoggedIn !== null) {
      return of(this.isLoggedIn);
    } else {
      return this.$loggedAccount.pipe(
        catchError((error)=>{
          this.setApiDown(true);
          console.error(error);
          throw error;
        }),
        map((account)=> {
          return account && account.idAccount != null;
        }),
        tap((res): void => {
          this.setApiDown(false);
          this.isLoggedIn = res;
        }));
    }
  }

  private setApiDown(apiDown: boolean): void {
    this.apiDown.next(apiDown);
  }

  public isApiDown(): Observable<boolean> {
    return this.apiDown.asObservable();
  }

  public login(username: string, password: string): Observable<boolean> {
    const options = { headers: this.headers, withCredentials: true };
    return this.http.post(`${environment.api_url}/authenticate`, JSON.stringify({ username: username, password: password, from: 'web' }), options).pipe(
      tap({
        next: (r: any): void => {
          if (r.success) {
            this.setAccount(r.account);
            const token = r.token;
            this.setToken(token);
            // this.setTokenHeader(token);
          }
          else {
            this.alertService.emitErrorMessage({
              text: r.message + ' 1 Incorrect username or password',
              type: 'danger',
            });
            this.isLoggedIn = false
          }
        },
        error: (err): void => {
          this.alertService.emitErrorMessage({
            text: err.error.message,
            type: 'danger',
          });
          if(err.error.maintenance) {
            this.setApiDown(true);
          }
          this.isLoggedIn = false;
        },
      }),
    );
  }
  // login(username: string, password: string): Observable<boolean> {
  //   const body = { username: username, password: password };

  //   return this.http
  //     .post(`${environment.api_url}/authenticate`, body, {
  //       headers: this.headers,
  //       withCredentials: true,
  //     })
  //     .pipe(
  //       map((response: any) => {
  //         if (response.success) {
  //           localStorage.clear();
  //           const token = response.token;
  //           this.setToken(token);
  //           this.loadAccount();
  //           return (this.isLoggedIn = true);
  //         } else {
  //           this.alertService.emitErrorMessage({
  //             text: 'Incorrect username or password',
  //             type: 'danger',
  //           });
  //           return (this.isLoggedIn = false);
  //         }
  //       }),
  //       catchError((err: any) => {
  //         this.alertService.emitErrorMessage({
  //           text: 'Incorrect username or password',
  //           type: 'danger',
  //         });
  //         this.isLoggedIn = false;
  //         return of(false);
  //       })
  //     );
  // }

  // login(username: string, password: string): Observable<boolean> {

  //   return this.http.post(`${environment.api_url}/authenticate`, JSON.stringify({ username: username, password: password }), this.options)
  //     .map((response: Response) => {
  //       let res = response.json();
  //       if (res.success) {

  //         // this.loggedAccount = {
  //         //   idAccount: res.account.idAccount,
  //         //   idRole: res.account.idRole,
  //         //   idOrganization: res.account.idOrganization,
  //         //   username: res.account.username,
  //         //   fullname: res.account.fullname,
  //         //   password: res.account.password,
  //         //   idLanguage: res.account.idLanguage,
  //         // };


  //         localStorage.clear();
  //         const token = res.token;
  //         this.setToken(token);
  //         // localStorage.setItem('token', res.token );
  //         // this.setTokenHeader();

  //         //Sentry user tracker
  //         // Raven.setUserContext({
  //         //   usermane: res.account.username,
  //         //   idAccount: res.account.idAccount
  //         // })

  //         this.loadAccount(); // this shouldn't be necessary

  //         return this.isLoggedIn = true
  //       } else {
  //         this.alertService.emitErrorMessage({text: 'Incorrect username or password', type: 'danger'});
  //         return this.isLoggedIn = false
  //       }
  //     })
  //     .catch((err: Response) => {
  //       this.alertService.emitErrorMessage({text: 'Incorrect username or password', type: 'danger'});
  //       this.isLoggedIn = false;
  //       //Emit error message
  //       this.alertService.emitErrorMessage({text: 'Incorrect username or password', type: 'danger'});
  //       //return Observable.throw(err.body);
  //       return Observable.of(false);
  //     });
  // }

  public logout(text: string = 'You have logged out'): void {
    this.isLoggedIn = false;
    localStorage.removeItem("token");
    // this.clearTokenHeader();
    window['_paq'].push(["deleteCookies"]);
    this.router.navigate(["login"]);
    this.alertService.emitErrorMessage({text, type: 'info'});

  }
  // logout(): void {
  //   this.isLoggedIn = false;
  //   localStorage.clear();
  //   // this.clearTokenHeader();
  //   window['_paq'].push(["deleteCookies"]);
  //   this.loggedAccount = null;

  //   // Clear Sentry tracker user info
  //   // Raven.setUserContext()

  //   // Get the redirect URL from our auth service
  //   // If no redirect has been set, use the default
  //   this.redirectUrl = '/login';

  //   // Set our navigation extras object
  //   // that passes on our global query params and fragment
  //   let navigationExtras: NavigationExtras = {
  //     //preserveQueryParams: true,
  //     queryParamsHandling: 'preserve',
  //     preserveFragment: true
  //   };

  //   // Redirect the account
  //   this.router.navigate([this.redirectUrl], navigationExtras);

  //   //Send error message
  //   this.alertService.emitErrorMessage({text: 'You have logged out', type: 'info'});

  //   // location.reload();

  // }

  impersonate(idAccount: number): Observable<boolean> {
    const token = localStorage.getItem('token');
    localStorage.setItem('token_impersonator', token);
  
    const body = { idAccount: idAccount };
  
    return this.http.post(`${environment.api_url}/impersonate`, body, {
      headers: this.headers
    })
    .pipe(
      map((response: any) => {
        if (response.success) {
          const newToken = response.token;
          this.setToken(newToken);
          this.loadAccount().then(() => {
            location.reload();
          });
  
          return (this.isLoggedIn = true);
        } else {
          this.alertService.emitErrorMessage({
            text: 'Incorrect username or password',
            type: 'danger',
          });
          return (this.isLoggedIn = false);
        }
      }),
      catchError((err: any) => {
        this.alertService.emitErrorMessage({
          text: 'Incorrect username or password',
          type: 'danger',
        });
        this.isLoggedIn = false;
        return of(false);
      })
    );
  }

  // impersonate(idAccount: Number): Observable<boolean> {
  //   this.options;
  //   let token = localStorage.getItem('token');
  //   localStorage.setItem('token_impersonator', token);
  //   return this.http.post(`${environment.api_url}/impersonate`, JSON.stringify({idAccount: idAccount}), this.options)
  //   // return this.http.post(`${environment.api_url}/impersonate`, idAccount, this.options)
  //     .map((response: Response) => {
  //       let res = response.json();
  //       if (res.success) {

  //         // this.loggedAccount = {
  //         //   idAccount: res.account.idAccount,
  //         //   idRole: res.account.idRole,
  //         //   idOrganization: res.account.idOrganization,
  //         //   username: res.account.username,
  //         //   fullname: res.account.fullname,
  //         //   password: res.account.password,
  //         //   idLanguage: res.account.idLanguage,
  //         // };

  //         //localStorage.clear();
  //         const token = res.token;
  //         this.setToken(token);
  //         // localStorage.setItem('token', res.token );
  //         // this.setTokenHeader();

  //         //Sentry user tracker
  //         // Raven.setUserContext({
  //         //   usermane: 'username',
  //         //   idAccount: 'res.idAccount'
  //         // })

  //         this.loadAccount().then(()=>{
  //           location.reload();
  //         });

  //         return this.isLoggedIn = true
  //       } else {
  //         this.alertService.emitErrorMessage({text: 'Incorrect username or password', type: 'danger'});
  //         return this.isLoggedIn = false
  //       }
  //     })
  //     .catch((err: Response) => {
  //       err;
  //       this.alertService.emitErrorMessage({text: 'Incorrect username or password', type: 'danger'});
  //       this.isLoggedIn = false;
  //       //Emit error message
  //       this.alertService.emitErrorMessage({text: 'Incorrect username or password', type: 'danger'});
  //       //return Observable.throw(err.body);
  //       return Observable.of(false);
  //     });
  // }

  endImpersonate(){
    localStorage.setItem('token',localStorage.getItem('token_impersonator'));
    localStorage.removeItem('token_impersonator');
    //this.loadAccount();
    window.location.replace('');
  }

  async getRole(){
    if (!this.loggedAccount){
      await this.loadAccount();
      return this.loggedAccount.idRole;
     } else {
       //console.log(this.loggedAccount.idRole);
       return this.loggedAccount.idRole;
     }
  }

  async isEndUser(){
    if (!this.loggedAccount){
      await this.loadAccount();
      return this.loggedAccount.idRole == 3;
     } else {
       //console.log(this.loggedAccount.idRole);
       return this.loggedAccount.idRole == 3;
     }
  }

  // async getAccount(){
  //   if (!this.loggedAccount){
  //     await this.loadAccount();
  //     return this.loggedAccount;
  //    } else {
  //      //console.log(this.loggedAccount);
  //      return this.loggedAccount;
  //    }
  // }

  public getAccount(): Observable<any>{
    if (this.loggedAccount){
      return of(this.loggedAccount);
    } else {
      return this.$loggedAccount;
    }
  }

  async loadAccount() {
    try {
      const r: any = await this.http
        .get(`${environment.api_url}/auth/account`, {
          headers: this.headers
        })
        .toPromise();

      if (r.status == 200) {
        this.loggedAccount = r;
        this.isLoggedIn = true;
      }
    } catch (e) {
      debugger;
      console.log(e);
      this.logout();
    }
  }
  // async loadAccount(){
  //   //if (localStorage.getItem('token')){
  //     // this.setTokenHeader();
  //     // const token = localStorage.getItem('token');
  //     //console.log(`${environment.api_url}/auth/account`);
  //     return await this.http.get(`${environment.api_url}/auth/account`, this.options).toPromise().then((r: any)=>{
  //       if (r.status == 200) {
  //         this.loggedAccount = r.json();
  //         this.isLoggedIn = true;
  //         //Sentry user tracker
  //         // Raven.setUserContext({
  //         //   usermane: this.loggedAccount.username,
  //         //   idAccount: this.loggedAccount.idAccount
  //         // });
  //       }
  //     }).catch((e)=>{
  //       debugger;
  //       console.log(e)
  //       this.logout();
  //     });
  //   // } else {
  //   //   return null;
  //   // }
  // }

  // setTokenHeader(): void {
  //   if(!this.options.headers.has('x-access-token'))
  //     this.options.headers.append('x-access-token', localStorage.getItem('token'));
  // }

  // private clearTokenHeader(): void {
  //   this.options.headers.delete('x-access-token');
  // }

  clearContentHeader(): void {
    this.options.headers.delete('Content-Type');
  }


  //getAccountRoles(id: string) {

    //return this.http.get(`${environment.server_url}/account/${id}`, this.options)
                    //.map((response: Response) => {
                      //localStorage.setItem('role', 'admin');
                    //})
                    //.catch((error: Response | any) => {
                      //this.alertService.emitErrorMessage('Failed to get the user');
                      //return Observable.of(false);
                    //});
  //}
}
