import { Component, OnInit } from '@angular/core';
//import { Location }            from '@angular/common';
import { Router }            from '@angular/router';

import { AuthService }   from '../../auth.service';
import { MainService }   from '../main.service';
import { Role }          from '../roles/role';
import { RoleService }   from '../roles/role.service';
import { AppService }   from '../apps/app.service';
import { App }          from '../apps/app';

import { QueryStore } from '../shared/store/query.store';
import { TabStore } from '../shared/store/tab.store';

@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.css']
})
export class ImpersonateComponent implements OnInit{

  accountImpersonated : any;

  constructor(
    private appService: AppService,
    private authService: AuthService,
    private queryStore: QueryStore,
    private tabStore: TabStore
  ) { }

  ngOnInit() {
    this.authService.getAccount().subscribe((account)=>{
      this.accountImpersonated = account;
    });
  }

  exitImpersonation() {
    this.authService.endImpersonate();
  }

}
