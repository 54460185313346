import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { OrganizationListComponent }   from './organization-list/organization-list.component';
import { OrganizationListResolver}     from './organization-list/organization-list-resolver.service';
import { OrganizationListWithoutEndUsersResolver } from './organization-list/organization-list-without-endUsers-resolver.service';
import { OrganizationEditComponent }   from './organization-edit/organization-edit.component';
import { OrganizationEditResolver }    from './organization-edit/organization-edit-resolver.service';
import { RoleListResolver }            from './../roles/role-list/role-list-resolver.service';
import { AppListResolver } from '../apps/app-list/app-list-resolver.service';

const organizationsRoutes: Routes = [
  //{ path: '', component: OrganizationListComponent },
  //{ path: 'organizations/:id', component: OrganizationDetailComponent },
  { path: '',
    children: [
      //{path: 'organizations', component: OrganizationListComponent, resolve: {organizations: OrganizationListResolver}},
      {path: 'new', component: OrganizationEditComponent,
        resolve: {
          roles: RoleListResolver,
          apps: AppListResolver,
          organizations: OrganizationListResolver
        }
      },
      {path: 'edit/:id', component: OrganizationEditComponent,
        resolve: {
          organization: OrganizationEditResolver,
          roles: RoleListResolver,
          organizations: OrganizationListResolver
        }
      },
      //{path: ':idProvider', component: OrganizationListComponent, resolve: {organizations: OrganizationListResolver}},
      //{path: ':id', component: OrganizationDetailComponent, resolve: {organization: OrganizationDetailResolver} },
      {path: '', component: OrganizationListComponent }
      //{path: '', component: OrganizationListComponent, resolve: {organizations: OrganizationListResolver}},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(organizationsRoutes)],
  exports: [RouterModule],
  providers: [
    OrganizationListResolver,
    OrganizationListWithoutEndUsersResolver,
    OrganizationEditResolver,
  ]
})
export class OrganizationsRoutingModule { }
