import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppListComponent }   from './app-list/app-list.component';
import { AppEditComponent }   from './app-edit/app-edit.component';

import { AppListResolver}     from './app-list/app-list-resolver.service';
import { AppEditResolver }    from './app-edit/app-edit-resolver.service';
import { MasterVariableAppListResolver }  from './../apps/app-list/masterVariable-app-list-resolver.service';
import { OrganizationAppListResolver } from './app-list/organization-app-list-resolver.service';
import { LicenseAppListResolver } from './app-list/license-app-list-resolver.service';
import { AccountAppListResolver } from './app-list/account-app-list-resolver.service';
import { ViewListResolver } from '../views/view-list/view-list-resolver.service';

const appsRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'new',
        component: AppEditComponent,
        resolve: {
          // views: ViewListResolver
        }
      },
      {
        path: 'edit/:id',
        component: AppEditComponent,
        resolve: {
          app: AppEditResolver,
          // views: ViewListResolver,
        }
      },
      {
        path: '',
        component: AppListComponent,
        resolve: {
          apps: AppListResolver
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(appsRoutes)],
  exports: [RouterModule],
  providers: [
    AppListResolver,
    MasterVariableAppListResolver,
    OrganizationAppListResolver,
    AccountAppListResolver,
    LicenseAppListResolver,
    AppEditResolver,
    ViewListResolver,
  ]
})
export class AppsRoutingModule { }
